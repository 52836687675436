import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Link } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as ApiLink from '../../link/ApiLink';
import { dateValueFormatter, euroValueFormatter } from '../../util/UiUtil';

type Props = {
    clientfiles: ApiLink.Clientfile[];
    searching: boolean;
    onRequestDeleteClientFile: (clientFileId: number) => void;
};

const ClientFilesGrid = (props: Props) => {
    //States
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: '',
            sortable: false,
            width: 50,
            align : 'center',
            renderCell: (params) => {
                let affectedByPayment = (params.row.remaining_commissions ?? 0) < (params.row.total_commissions ?? 0);
                let firstDuedatePassed = params.row.first_duedate && (params.row.first_duedate < new Date().toISOString().substring(0,10));
                let disableDeletion = affectedByPayment || firstDuedatePassed; 
                return (
                    <IconButton sx={{padding:0}}
                        color='error' 
                        onClick={e => { 
                            if (props.onRequestDeleteClientFile)
                                props.onRequestDeleteClientFile(params.row.id);
                        }} 
                        disabled={disableDeletion}
                        >
                            <DeleteIcon />
                    </IconButton>
                );
            }
        },
        { 
            field: 'invoice_number', 
            headerName: 'Facture n°', 
            width: 150,
            renderCell: (params) => {
                let url = "/clientfiles/file/" + params.row.id;
                return (
                    <Link   component={RouterLink}
                            to={url}
                            sx={{fontStyle: params.row.invoice_number ? 'inherit' : 'italic'}}
                            className='MuiDataGrid-cellContent'>
                        {params.row.invoice_number || '(N/A)'}
                    </Link>
                );
            }
        },
        {
            field: 'invoice_date',
            headerName: 'Émise le',
            width: 85,
            valueFormatter: dateValueFormatter
        },
        { 
            field: 'label', 
            headerName: 'Libellé', 
            width: 260,
            renderCell: (params) => {
                let url = "/clientfiles/file/" + params.row.id;
                return (
                    <Link   component={RouterLink}
                            to={url}
                            className='MuiDataGrid-cellContent'>
                        {params.row.label}
                    </Link>
                );
            }
        },
        { 
            field: 'status', 
            headerName: 'Statut', 
            width: 90,
            valueGetter(params) {
                switch (params.row.status) {
                    case 'TODO':
                        return 'À échéancer';
                    case 'ONGOING':
                        return 'En cours';
                    case 'TOCOME':
                        return 'À venir';
                    case 'PAYED':
                        return 'Payé par client';
                    case 'DONE':
                        return 'Soldé';
                }
            },
            cellClassName: (params) => `cell-${params.row.status}`,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'client_name',
            headerName: 'Client',
            width: 180,
            valueGetter(params) {
                return params.row.client.company_name;
            },
        },
        {
            field: 'user',
            headerName: 'Commercial',
            width: 100,
            valueGetter(params) {
                return params.row.user.name;
            },
        },
        {
            field: 'amount_ht',
            headerName: 'Montant HT',
            width: 100,
            valueFormatter: euroValueFormatter,
            headerAlign: 'center',
            align: 'right'
        },
        {
            field: 'amount_ttc',
            headerName: 'Montant TTC',
            width: 100,
            valueFormatter: euroValueFormatter,
            headerAlign: 'center',
            align: 'right'
        },
    ];

    return (
        <DataGrid
            sx={{
                mt: 1,
                cursor: 'default',
                '& .MuiDataGrid-row a': {
                    textDecoration: 'none',
                    color: 'inherit'
                },
                '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#f0634220',
                    '& a': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    },
                },
                //Rows also have their class according to status
                //To apply the color to the entire row -> '& .row-TODO'
                '& .cell-TODO': {
                    bgcolor: 'lightgrey'
                },
                '& .cell-ONGOING': {
                    bgcolor: 'lightsalmon'
                },
                '& .cell-TOCOME': {
                    bgcolor: 'aliceblue'
                },
                '& .cell-PAYED': {
                    bgcolor: 'lightpink'
                },
                '& .cell-DONE': {
                    bgcolor: 'lightgreen'
                }
            }}
            rows={props.clientfiles}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {pageSize: 10},
                },
            }}
            pageSizeOptions={[10, 20, 50, 100]}
            rowSelection={false}
            disableRowSelectionOnClick={true}
            onRowDoubleClick={(params) => {
                navigate("/clientfiles/file/" + params.row.id);
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: { showQuickFilter: true }
            }}
            disableDensitySelector
            density='compact'
            loading={props.searching}
        />
    );
};

export default ClientFilesGrid;
