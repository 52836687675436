import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RouteDef } from "../../routes/appRoutes";
import { RootState } from "../../redux/store";

export type Props = {
  item: RouteDef
}

const SidebarItem = ({ item } : Props) => {
  const { menuState } = useSelector((state: RootState) => state.menuPage);

  return (
    item.state && item.state.sidebarProps && item.path ? (
      <ListItemButton
        component={Link}
        to={item.path}
        sx={{
          "&: hover": {
            bgcolor: 'action.hover'
          },
          "&.active": {
            bgcolor: 'secondary.light'
          }
        }}
        className={menuState && menuState.menu === item.state.menu ? 'active' : 'inactive'}
      >
        <ListItemIcon sx={{ minWidth: '2.5em', color: 'inherit' }}>
          {item.icon && item.icon}
        </ListItemIcon>
        {item.state.sidebarProps.displayText}
      </ListItemButton>
    ) : null
  );
};

export default SidebarItem;