import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Box, Container, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { dateValueFormatter, euroValueFormatter, priceFormatter } from '../../util/UiUtil';

type Props = {
    commissionlines: any
};

const CommissionPanel = (props : Props) => {
    //States
    const [ totalCommissionsHT, setTotalCommissionsHT ] = React.useState<number>(0);
    const [ totalCommissionsTTC, setTotalCommissionsTTC ] = React.useState<number>(0);

    //Load
    React.useEffect(() => {
        if (props.commissionlines != null) {
            let totalHT: number = 0;
            let totalTTC: number = 0;
            props.commissionlines.forEach((line: ApiLink.Commissionline) => {
                totalHT += line.salesman_share_ht ?? 0;
                totalTTC += line.salesman_share_ttc ?? 0;
            });

            setTotalCommissionsHT(totalHT);
            setTotalCommissionsTTC(totalTTC);
        }
    }, [props.commissionlines]);

    //Grid Columns
    const columns: GridColDef[] = [
        { 
            field: 'date', 
            headerName: 'Date',
            sortable: false,
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter(params) {
                return params.row.duedate.date;
            },
            valueFormatter: dateValueFormatter
        },
        { 
            field: 'amount_ht', 
            headerName: 'Payé',
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueGetter(params) {
                return params.row.duedate.amount_ht;
            },
            valueFormatter: euroValueFormatter
        },
        { 
            field: 'production_cost', 
            headerName: 'Fab.',
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: euroValueFormatter
        },
        { 
            field: 'investment', 
            headerName: 'Invest. HT', 
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueGetter(params) {
                return params.row.duedate.investment;
            },
            valueFormatter: euroValueFormatter
        },
        { 
            field: 'margin', 
            headerName: 'Marge', 
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: euroValueFormatter
        },
        { 
            field: 'sudmedia_share', 
            headerName: '%SudMedia', 
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: euroValueFormatter
        },
        { 
            field: 'salesman_share_ht', 
            headerName: '%Comm HT', 
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: euroValueFormatter
        },
        { 
            field: 'salesman_share_ttc', 
            headerName: '%Comm TTC', 
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: euroValueFormatter
        },
    ];

    //Layout
    return (
        <Box className='commission' sx={{ mt:4 }}>
            <Typography component='h2' variant='h5'>Echéancier des commissions (HT)</Typography>

            <Container sx={{px: 0, '@media (min-width: 600px)': { padding: "0"}}}>
                <DataGrid
                    sx={{ mt: '20px' }}
                    rows={props.commissionlines}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                        sorting: {
                            sortModel: [{ field: 'date', sort: 'asc' }],
                        },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    autoHeight={true}
                    density='compact'
                    rowSelection={false}
                    disableRowSelectionOnClick={true}
                />
                
                <Stack direction='row' gap={1} sx={{ mt:'10px' }} alignItems="center">
                    <Typography variant='h6'>Total des commissions : </Typography>
                    <Typography variant='h6' component="span" fontWeight="bold" color="secondary">{priceFormatter.format(totalCommissionsHT) + " HT"}</Typography>
                    <Typography variant='h6' component="span" fontWeight="bold" color="secondary">{"|"}</Typography>
                    <Typography variant='h6' component="span" fontWeight="bold" color="secondary">{priceFormatter.format(totalCommissionsTTC) + " TTC"}</Typography>
                </Stack>
                
            </Container>
        </Box>
    );
};

export default CommissionPanel;