import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { store } from './redux/store';
import { themeOptions } from './configs/themeOptions';
import { frFR as gridFrFR } from '@mui/x-data-grid';
import { frFR } from '@mui/material/locale';
import { frFR as dpFrFR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/fr';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const theme = createTheme(themeOptions, frFR, gridFrFR, dpFrFR);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <CssBaseline />
                <App />
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
