import { configureStore } from "@reduxjs/toolkit";
import menuPageSlice from "./features/menuPageSlice";
import loginSlice from "./features/loginSlice";

export const store = configureStore({
  reducer: {
    menuPage: menuPageSlice,
    login: loginSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;