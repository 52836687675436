import { Paper, Typography } from '@mui/material';
import './LoadingScreen.css';
import { ReactNode } from 'react';
import * as appInfo from '../configs/appInfo';

type Props = {
    connecting: boolean
};

function LoadingScreen({ connecting } : Props) {

    let connectingEl : ReactNode = null;
    if (connecting) {
        connectingEl = <Typography variant='h5' className="connecting">{connecting && "Connexion en cours..."}</Typography>;
    }

    document.title = appInfo.shortName;

    return (
        <Paper className="LoadingScreen">
            <header>
                <Typography variant='h2'>{appInfo.longName}</Typography>
            </header>
            <footer>
                { connectingEl }
            </footer>
        </Paper>
    );

}

export default LoadingScreen;