import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js-light';

type Props = {
    duedate: ApiLink.Duedate|undefined,
    setDuedate: any,
    editDuedate: any,
    conversionRate: number
};

const DuedateEditDialog = (props : Props) => {
    //States
    const [ date, setDate ] = React.useState<string>('');
    const [ amountHT, setAmountHT ] = React.useState<number|null>(null);
    const [ amountTTC, setAmountTTC ] = React.useState<number|null>(null);
    const [ investment, setInvestment ] = React.useState<number|null>(null);

    //Load
    React.useEffect(() => {
        if (props.duedate) {
            setDate(props.duedate.date ? props.duedate.date : '');
            setAmountHT(props.duedate.amount_ht);
            setAmountTTC(props.duedate.amount_ttc);
            setInvestment(props.duedate.investment);
        }
    }, [props]);

    //Layout
    if (props.duedate) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <Box component='form' onSubmit={e => {
                    if (props.duedate) {
                        const dd = {
                            id: props.duedate.id,
                            date: date,
                            amount_ht: amountHT,
                            amount_ttc: amountTTC,
                            investment: investment,
                            clientfile_id: props.duedate.clientfile_id,
                            toUpdate: true
                        }
                        props.editDuedate(e, dd);
                        props.setDuedate(undefined);
                    }
                }}>
                <DialogTitle id="alert-dialog-title">
                    {"Gestion des échéances"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Modifier l'échéance sélectionnée
                    </DialogContentText>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            label="Date d'échéance"
                            format='DD/MM/YYYY'
                            slotProps={{
                                field:{clearable: true},
                                textField: {
                                    required: true,
                                },
                            }}
                            value={date === '' ? null : dayjs(date)}
                            onChange={(value) => setDate(dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '')}
                        />
                    </LocalizationProvider>
                    <TextField 
                        id="amountHT" name="amountHT" label="Montant HT" fullWidth required
                        type="number"
                        variant="standard" sx={{mt: '8px'}} 
                        value={amountHT}
                        onChange={e => {
                            if (e.target.value === '') {
                                setAmountHT(null);
                                setAmountTTC(null);
                            } else {
                                let v = new Decimal(Number(e.target.value));
                                let ttcValue = v.mul(props.conversionRate).toDecimalPlaces(2);
                                setAmountHT(v.toNumber());
                                setAmountTTC(ttcValue.toNumber());
                            }
                        }}
                    />
                    <TextField 
                        id="amountTTC" name="amountTTC" label="Montant TTC" fullWidth required
                        type="number"
                        variant="standard" sx={{mt: '8px'}} 
                        value={amountTTC}
                        onChange={e => {
                            if (e.target.value === '') {
                                setAmountTTC(null);
                                setAmountHT(null);
                            } else {
                                let v = new Decimal(Number(e.target.value));
                                let htValue = v.dividedBy(props.conversionRate).toDecimalPlaces(2);
                                setAmountTTC(v.toNumber());
                                setAmountHT(htValue.toNumber());
                            }
                        }}
                    />
                    <TextField 
                        id="investment" name="investment" label="Investissement" fullWidth required
                        type="number"
                        variant="standard" sx={{mt: '8px'}} 
                        value={investment}
                        onChange={e => setInvestment(e.target.value === '' ? null : Number(e.target.value))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => props.setDuedate(undefined)} autoFocus variant='contained' color='primary'>Annuler</Button>
                    <Button type='submit' variant='contained' color='secondary'>OK</Button>
                </DialogActions>
                </Box>
            </Dialog>
        );
    }

    return null;
};

export default DuedateEditDialog;