import { Box, Link } from "@mui/material";
import { DataGrid, GridColDef, GridRowHeightParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React from "react";
import { priceFormatter } from "../../util/UiUtil";
import PaymentDuedateDetails from "./PaymentDuedateDetails";
import PaymentRecapDetails from "./PaymentRecapDetails";

type Props = {
    rows: Object[],
    dates: string[],
    setAlertText: any,
    salesman_id: string|null
};

const PaymentRecapGrid = (props: Props) => {
    //States
    const [ duedateDetailsDate, setDuedateDetailsDate ] = React.useState<Date>();
    const [ paymentDetailsDate, setPaymentDetailsDate ] = React.useState<Date>();

    //Grid Columns
    const [ columns, setColumns ] = React.useState<GridColDef[]>([
        { field: 'name', headerName: '', width: 150 }
    ]);

    //Create columns dynamically according to number of dates fetched for the recap
    React.useEffect(() => {
        let columns: GridColDef[] = [];
        columns.push({ 
            field: 'name', headerName: '', headerClassName: 'recap-header', width: 160, align: 'center', sortable: false,
            cellClassName: (params) => {
                if (params.row.id === 7) {
                    return 'recap-cell-title separator';
                } else {
                    return 'recap-cell-title';
                }
            },
            valueGetter: (params) => {
                switch(params.row.id) {
                    case 1:
                        return 'Paiements clients';
                    case 2:
                        return 'Marge';
                    case 3:
                        return 'Commissions';
                    case 4:
                        return 'Coms réglées HT';
                    case 5:
                        return 'Restant-dû HT';
                    case 6:
                        return 'Restant-dû TTC';
                    case 7:
                        return 'Règlements faits HT';
                    case 8:
                        return 'Règlements faits TTC'
                }
            },
        });
        props.dates.forEach(date => {
            let formattedDate = dayjs(date).format('MM/YYYY');
            columns.push({
                field: date,
                cellClassName: (params) => {
                    let classes = 'recap-cell';
                    if (params.row.id === 6 || params.row.id === 8) {
                        classes += ' TTC';
                    } 
                    if (params.row.id === 7) {
                        classes += ' separator';
                    }
                    if (params.row.id === 5 || params.row.id === 7) {
                        classes += ' highlight';
                    }

                    return classes;
                },
                headerName: formattedDate,
                headerClassName: (params) => {
                    if (dayjs(date).isAfter(dayjs(), 'month'))
                        return 'recap-header future';
                    else
                        return 'recap-header';
                }, 
                width: 90,
                align:'right',
                headerAlign: 'center',
                sortable: false,
                renderCell: (params) => {
                    return (
                        <Link   className='MuiDataGrid-cellContent'>
                                {priceFormatter.format(params.value)}
                        </Link>
                    );
                }
            });
        });
        setColumns(columns);
    }, [props.dates]);

    return (
        <>
            <Box>
                <DataGrid
                    sx={{
                        mt: 1,
                        '& .MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                            '& .recap-header': {
                                backgroundColor: 'primary.main', 
                                color: 'primary.contrastText'
                            },
                            '& .recap-header.future': {
                                backgroundColor: '#f06342BB'
                            },
                        },
                        '& .MuiDataGrid-row': {
                            '& a': {
                                textDecoration: 'none',
                                color: 'inherit'
                            },
                            "&.Mui-hovered": {
                                filter: 'brightness(90%) contrast(90%)',
                                '& a': {
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                },
                            },
                            '&:nth-of-type(even) .recap-cell': {
                                backgroundColor: '#f0634220'
                            }
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                        '& .recap-cell-title': {
                            backgroundColor: 'primary.main', 
                            color: 'primary.contrastText',
                            '&.separator': {
                                alignItems: 'flex-end',
                                paddingBottom: '15px'
                            },
                        },
                        '& .recap-cell': {
                            borderRight: '1px solid',
                            '&.separator': {
                                alignItems: 'flex-end',
                                paddingBottom: '15px'
                            },
                            '&.TTC .MuiDataGrid-cellContent': {
                                color: 'grey'
                            },
                            '&.highlight .MuiDataGrid-cellContent': {
                                fontWeight: '600'
                            },
                        },
                        '& .MuiDataGrid-footerContainer': {
                            display: 'none'
                        },
                    }}
                    rows={props.rows}
                    columns={columns}
                    pageSizeOptions={[10, 20, 50, 100]}
                    autoHeight={true}
                    rowSelection={false}
                    disableRowSelectionOnClick={true}
                    disableColumnMenu={true}
                    onCellClick={onCellClick}
                    getRowHeight={({ id, densityFactor }: GridRowHeightParams) => {
                        if ((id as number) === 7) {
                          return 100;
                        }
              
                        return null;
                    }}
                    density="compact"
                />
            </Box>

            <PaymentDuedateDetails 
                date={duedateDetailsDate}
                setDate={setDuedateDetailsDate}
                setAlertText={props.setAlertText}
                salesman_id={props.salesman_id}
            />

            <PaymentRecapDetails
                date={paymentDetailsDate}
                setDate={setPaymentDetailsDate}
                setAlertText={props.setAlertText}
                salesman_id={props.salesman_id}
            />
        </>
    );

    //Event handler
    function onCellClick(params: any, event: any, details: any) {
        //On cell click we check for which row the event has been sent
        if (params.field === 'name') {
            return;
        }
        //If it's the first 6 rows display duedates infos, if it's the 7th display payments infos, else do nothing
        if (params.id <= 6) {
            console.log('display duedates details for : ' + params.field);
            //Open dialog with date asked
            setDuedateDetailsDate(new Date(params.field));
        } else if (params.id >= 7) {
            console.log('display payments details for : ' + params.field);
            //Open dialog with date asked
            setPaymentDetailsDate(new Date(params.field));
        }
    }
}

export default PaymentRecapGrid;