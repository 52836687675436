import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as ApiLink from '../../link/ApiLink';
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    salesmen: ApiLink.User[],
    applyFilter: (s: string|null, sd: string|null, ed: string|null) => void,
    salesmanLoggedIn: string|undefined
};

const PaymentFilter = (props : Props) => {
    //States
    const [ salesman, setSalesman ] = React.useState<string|null>(null);
    const [ startDate, setStartDate ] = React.useState<string|null>(null);
    const [ endDate, setEndDate ] = React.useState<string|null>(null);

    //Layout
    return (
        <Box component={'form'} className='filter-form'
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                props.applyFilter(salesman, startDate, endDate);
            }}
            sx={{ px :0, '@media (min-width: 600px)': { padding: "0"},
                '@media (min-width: 1200px)':{width: '100%'},
                '& .filter-form': {
                    width: '100%',
                    margin: '0',
                    padding: 0,
                },
            }}
        >
            <Stack direction='row' useFlexGap spacing={2} flexWrap='wrap' alignItems='flex-end'>
                <TextField
                    sx={{ width: 200 }}
                    name='salesman'
                    label="Commercial" 
                    value={props.salesmanLoggedIn ? props.salesmanLoggedIn : salesman ?? ''}
                    onChange={e => setSalesman(e.target.value === '' ? null : e.target.value)}
                    select
                    disabled={props.salesmanLoggedIn ? true : false}
                >
                    <MenuItem key="all" value=""><em>Choisir un commercial</em></MenuItem>
                    {props.salesmen.map((salesman) => {
                        return (
                            <MenuItem key={salesman.id} value={salesman.id}>{salesman.name}</MenuItem>
                        );
                    })}
                </TextField>
                <Stack direction='row' useFlexGap spacing={2} flexWrap='nowrap' alignItems='flex-end'>
                    <Typography>Période du&nbsp;: </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            sx={{ width: 140 }}
                            label="Mois dernier"
                            slotProps={{
                                field:{clearable: true}
                            }}
                            onChange={(value: string | null) => setStartDate(value == null ? null : dayjs(value).format('YYYY-MM-DD'))}
                        />
                    </LocalizationProvider>
                    <Typography>jusqu'à&nbsp;: </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            sx={{ width: 140 }}
                            label="Aujourd'hui"
                            slotProps={{
                                field:{clearable: true}
                            }}
                            onChange={(value: string | null) => setEndDate(value == null ? null : dayjs(value).format('YYYY-MM-DD'))}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack direction='row' flexGrow='1' justifyContent='right'>
                    <Button type="submit" variant='contained' color='primary' sx={{width:'fit-content', height:'fit-content'}} startIcon={<SearchIcon/>}>Appliquer</Button>
                </Stack>
            </Stack>
        </Box>
    );
}

export default PaymentFilter;