import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { enqueueSnacks } from '../common/CustomSnackbar';
import { Decimal } from 'decimal.js-light';

type Props = {
    payment: ApiLink.Payment|undefined,
    setPayment: any,
    salesmen: ApiLink.User[],
    setReloadPayments: any,
    conversionRate: number, 
    setAlertText: any
};

const PaymentEditDialog = (props : Props) => {
    //States
    const [ payment, setPayment ] = React.useState<ApiLink.Payment>();
    const [ amountHT, setAmountHT ] = React.useState<string>('');
    const [ amountTTC, setAmountTTC ] = React.useState<string>('');
    const [ loading, setLoading ] = React.useState<boolean>(false);

    const setHT = (value: string) => {
        setAmountHT(value);

        if (value === '') {
            setAmountTTC('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.mul(props.conversionRate);
            setAmountTTC(nv.toDecimalPlaces(2).toString());
        }
    };

    const setTTC = (value: string) => {
        setAmountTTC(value);

        if (value === '') {
            setAmountHT('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.dividedBy(props.conversionRate)
            setAmountHT(nv.toDecimalPlaces(2).toString());
        }
    };

    //Load
    React.useEffect(() => {
        setPayment(props.payment);
        if (props.payment) {
            setAmountHT(props.payment.amount_ht ? props.payment.amount_ht.toString() : '');
            setAmountTTC(props.payment.amount_ttc ? props.payment.amount_ttc.toString() : '');
        }
    }, [props]);

    //Update payment when amountHT or amountTTC change
    React.useEffect(() => {
        setPayment(payment => {
            if (payment) {
                payment.amount_ht = Number(amountHT);
                payment.amount_ttc = Number(amountTTC);
            }

            return payment;
        });
    }, [amountHT, amountTTC])

    //Layout
    if (props.payment) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <Box component='form' onSubmit={e => {
                    e.preventDefault();
                    editSelectedPayment(e);
                }}>
                <DialogTitle id="alert-dialog-title">
                    {"Gestion des règlements"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Modifier le règlement sélectionné
                    </DialogContentText>
                    <TextField
                        fullWidth
                        name='salesman'
                        label="Commercial" 
                        value={payment ? payment.users_id : ""}
                        onChange={e => {
                            if (payment) {
                                setPayment({...payment, users_id: Number(e.target.value)});
                            }
                        }}
                        select
                        required
                    >
                        <MenuItem key="all" value=""><em>Choisir un commercial</em></MenuItem>
                        {props.salesmen.map((salesman) => {
                            return (
                                <MenuItem key={salesman.id} value={salesman.id}>{salesman.name}</MenuItem>
                            );
                        })}
                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            label="Date de règlement"
                            format='DD/MM/YYYY'
                            slotProps={{
                                field:{clearable: true},
                                textField: {
                                    required: true,
                                },
                            }}
                            value={payment ? dayjs(payment.date) : null}
                            onChange={value => handleDateChange(value)}
                        />
                    </LocalizationProvider>
                    <TextField name="amountHT" label="Montant HT" type='number' sx={{mt: '8px'}} fullWidth required
                        value={amountHT} 
                        onChange={e => setHT(e.target.value)} 
                    />
                    <TextField name="amountTTC" label="Montant TTC" type='number' sx={{mt: '8px'}} fullWidth required
                        value={amountTTC} 
                        onChange={e => setTTC(e.target.value)} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => props.setPayment(undefined)} autoFocus variant='contained' color='primary'>Annuler</Button>
                    <Button type='submit' variant='contained' color='secondary'>OK</Button>
                </DialogActions>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        );
    }

    return null;

    //Event handlers

    function handleDateChange(value: dayjs.Dayjs | null) {
        if (payment) {
           setPayment({...payment, date: dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : ''}); 
        }
    }

    function editSelectedPayment(event: React.FormEvent) {
        if (payment) {
            setLoading(true);
            ApiLink.updatePayment(payment)
            .then(() => {
                enqueueSnacks("Modification réussie");
            })
            .catch((err) => {
                props.setAlertText(err.message);
                enqueueSnacks(undefined, undefined, "Erreur de modification du paiement");
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
                props.setPayment(undefined);
            });

            props.setReloadPayments(true);
        }
    }
};

export default PaymentEditDialog;