import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouteState } from "../../routes/appRoutes";

export type MenuPageState = {
  menuState?: RouteState
}

const initialState : MenuPageState = {
  menuState: undefined
};

export const menuPageSlice = createSlice({
  name: "menuPage",
  initialState,
  reducers: {
    setMenuState: (state: MenuPageState, action: PayloadAction<RouteState>) => {
      state.menuState = action.payload;
    }
  }
});

export const {
  setMenuState
} = menuPageSlice.actions;

export default menuPageSlice.reducer;