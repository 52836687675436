import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenuState } from "../../redux/features/menuPageSlice";
import { ReactNode } from "react";
import { RouteState } from "../../routes/appRoutes";

type Props = {
  state?: RouteState,
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setMenuState(props.state));
    }
  }, [dispatch, props]);

  return (
    <>{props.children}</>
  );
};

export default PageWrapper;