import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Toolbar } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClientFilesFilter from '../../components/clientfiles/ClientFilesFilter';
import ClientFilesGrid from '../../components/clientfiles/ClientFilesGrid';
import CustomSnackbar, { enqueueSnacks } from '../../components/common/CustomSnackbar';
import * as ApiLink from '../../link/ApiLink';
import { LoginState } from '../../redux/features/loginSlice';
import sizeConfigs from '../../configs/sizeConfigs';

type Props = {};

const HistoryPage = (props: Props) => {
    //States
    const { user } = useSelector((state: { login: LoginState }) => state.login);
    const [ clientfiles, setClientfiles ] = React.useState<ApiLink.Clientfile[]>([]);
    const [ alertText, setAlertText ] = React.useState<string>();
    const [ clientfileToDelete, setClientfileToDelete ] = React.useState<number>();
    const [ reload, setReload ] = React.useState<boolean>(true);
    const [ searching, setSearching ] = React.useState<boolean>(false);

    const searchClientfiles = (clientId: string | null, salesmanId: string | null, startDate: string | null, endDate: string | null) => {
        console.log('filtering clients with client_id:' + clientId + ' | salesman_id:' + salesmanId +
            ' | start_date:' + startDate + ' | end_date:' + endDate);

        setSearching(true);
        ApiLink.getFilteredClientfiles(clientId, salesmanId, startDate, endDate)
        .then(clientfiles => {
            if (!clientfiles) clientfiles = [];
            setClientfiles(clientfiles)
        })
        .catch(err => setAlertText(err.message))
        .finally(() => {
            setSearching(false);
        });
    };

    //Load
    React.useEffect(() => {
        if (reload) {
            let salesman_id = null;
            if (user && user.auth_group === ApiLink.AuthGroup.SALESMAN) {
                salesman_id = user.id.toString();
            }
            searchClientfiles(null, salesman_id, null, null);
            setReload(false);
        }
    }, [user, reload]);

    //Layout
    return (
        //maxWidth is 100 vw - 250px (sidebar) - 32px (padding 16px each side)
        <Container sx={{maxWidth: `calc(100vw - 32px - ${sizeConfigs.sidebar.width})`, px :0, '@media (min-width: 600px)': {
            padding: "0"}, '@media (min-width: 1200px)':{maxWidth: `calc(100vw - 32px - ${sizeConfigs.sidebar.width})`}} 
          }>
            
            <Container sx={{px: 0, '@media (min-width: 600px)': {
            padding: "0"}}}>
                <ClientFilesFilter onSubmit={searchClientfiles} user={user} />
            </Container>
            <Container sx={{height: '35.05rem', px: 0, '@media (min-width: 600px)': {
            padding: "0"}}}>

                <ClientFilesGrid clientfiles={clientfiles} searching={searching} onRequestDeleteClientFile={setClientfileToDelete} />

                <Toolbar sx={{ justifyContent: 'flex-end', px: 0, '@media (min-width: 600px)': {padding: "0"}}}>
                    <Link to="/clientfiles/file">
                        <Button variant='contained' color='secondary' startIcon={<PlaylistAddIcon />}>Nouveau</Button>
                    </Link>
                </Toolbar>
                
            </Container>

            <LocalAlertDialog />
            <DeleteDialog />
            <CustomSnackbar />
        </Container>
    );

    //Event handlers

    function deleteClientfile(event: React.MouseEvent) {
        if (clientfileToDelete) {
            ApiLink.deleteClientfile(clientfileToDelete)
            .then(() => {
                enqueueSnacks("Suppression réussie");
                setReload(true);
            })
            .catch((err) => {
                enqueueSnacks(undefined, undefined, "Erreur de suppression du paiement");
                console.error(err);
            });
        }
    }

    //Inner components

    function LocalAlertDialog(props: {}): React.ReactElement | null {
        if (alertText) {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion des clients"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setAlertText(undefined)} autoFocus variant='contained' color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    function DeleteDialog(props: { }) : React.ReactElement | null {
        if (clientfileToDelete) {
            return (
                <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Suppression d'échéance"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Voulez-vous supprimer le dossier {clientfileToDelete} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => setClientfileToDelete(undefined)} autoFocus>Annuler</Button>
                    <Button onClick={e => {
                        setClientfileToDelete(undefined);
                        deleteClientfile(e);
                    }}
                    variant='contained' color='secondary'
                    >
                    OK
                    </Button>
                </DialogActions>
                </Dialog>
            );
        }
        return null;
    }
};

export default HistoryPage;