import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DuedateEditDialog from './DuedateEditDialog';
import DuedateBulkAddDialog from './DuedateBulkAddDialog';
import { Decimal } from 'decimal.js-light';
import { dateValueFormatter, priceFormatter } from '../../util/UiUtil';

type Props = {
    clientfile: ApiLink.Clientfile | undefined,
    duedates: any,
    setAlertText: any,
    onDuedatesAddition: any,
    onDuedateUpdate: any,
    onDuedateDelete: any,
    conversionRate: number,
    remainingAmount: Decimal,
    blockDuedates: boolean
};

const DuedatePanel = (props : Props) => {
    //States
    const [ amountHT, setAmountHT ] = React.useState<string>('');
    const [ amountTTC, setAmountTTC ] = React.useState<string>('');
    const [ date, setDate ] = React.useState<string>('');
    const [ investment, setInvestment ] = React.useState<string>('');
    const [ idCount, setIdCount ] = React.useState<number>(-1);
    const [ duedateToEdit, setDuedateToEdit ] = React.useState<ApiLink.Duedate>();
    const [ duedateToDelete, setDuedateToDelete ] = React.useState<ApiLink.Duedate>();
    const [ bulkAdd, setBulkAdd ] = React.useState<boolean>(false);

    const setHT = (value: string) => {
        setAmountHT(value);

        if (value === '') {
            setAmountTTC('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.mul(props.conversionRate);
            setAmountTTC(nv.toDecimalPlaces(2).toString());
        }
    };

    const setTTC = (value: string) => {
        setAmountTTC(value);

        if (value === '') {
            setAmountHT('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.dividedBy(props.conversionRate)
            setAmountHT(nv.toDecimalPlaces(2).toString());
        }
    };

    //Load
    React.useEffect(() => {

    }, []);

    //Grid Columns
    const columns: GridColDef[] = [
        
        { 
            field: 'delete', 
            headerName: '',
            sortable: false,
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton sx={{padding:0}}
                        color='error' 
                        onClick={e => setDuedateToDelete(params.row)} 
                        disabled={props.blockDuedates}
                    > 
                        <DeleteIcon />
                    </IconButton>
                );
            }
        },
        { 
            field: 'date', 
            headerName: 'Date',
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: dateValueFormatter,
            renderCell: (params) => {
                return (
                    <Link style={{ textDecoration: 'none' }} className='MuiDataGrid-cellContent'>
                        <Typography
                            onClick={e => setDuedateToEdit(params.row)} 
                            variant='inherit'
                            sx={{ color: 'black' }}
                            className='MuiDataGrid-cellContent'
                        >
                            {params.row.date}
                        </Typography>
                    </Link>
                );
            }
        },
        { 
            field: 'amount_ht', 
            headerName: 'Montant HT',
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Link style={{ textDecoration: 'none' }} className='MuiDataGrid-cellContent'>
                        <Typography
                        onClick={e => setDuedateToEdit(params.row)} 
                        variant='inherit'
                        sx={{ color: 'black' }}
                        className='MuiDataGrid-cellContent'>
                            {priceFormatter.format(params.row.amount_ht)}
                        </Typography>
                    </Link>
                );
            }
        },
        { 
            field: 'amount_ttc', 
            headerName: 'Montant TTC',
            sortable: false,
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Link style={{ textDecoration: 'none' }} className='MuiDataGrid-cellContent'>
                        <Typography
                        onClick={e => setDuedateToEdit(params.row)} 
                        variant='inherit'
                        sx={{ color: 'black' }}
                        className='MuiDataGrid-cellContent'>
                            {priceFormatter.format(params.row.amount_ttc)}
                        </Typography>
                    </Link>
                );
            }
        },
        { 
            field: 'investment', 
            headerName: 'Invest. HT', 
            sortable: false,
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Link style={{ textDecoration: 'none' }} className='MuiDataGrid-cellContent'>
                        <Typography
                        onClick={e => setDuedateToEdit(params.row)} 
                        variant='inherit'
                        sx={{ color: 'black' }}
                        className='MuiDataGrid-cellContent'>
                            {priceFormatter.format(params.row.investment)}
                        </Typography>
                    </Link>
                );
            }
        },
    ];

    //Layout
    return (
        <Box>
            <Grid container sx={{ mt:4 }} columnGap={2}>
                <Grid item xs={6}>
                    <Typography component='h2' variant='h5'>Echéancier de paiement</Typography>

                    <DataGrid
                        sx={{  
                            mt: '20px', 
                            '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#f0634220',
                            '& .MuiDataGrid-cellContent .MuiTypography-root': {
                                textDecoration: 'underline',
                            },
                        }, }}
                        rows={props.duedates}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            sorting: {
                                sortModel: [{ field: 'date', sort: 'asc' }],
                            },
                        }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        autoHeight={true}
                        rowSelection={false}
                        density='compact'
                        disableRowSelectionOnClick={true}
                        onRowDoubleClick={(params, event, details) => {
                            if (!props.blockDuedates) {
                                setDuedateToEdit(params.row);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={5} sx={{ marginTop:'50px' }}>
                    <Typography component='h2' variant='h5'>Ajouter une entrée</Typography>
                    <Grid container columnGap={2} rowGap={2} justifyContent='flex-start' alignItems='flex-end'>
                        <Grid item xs={5}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                <DatePicker
                                    label="Date d'échéance"
                                    format='DD/MM/YYYY'
                                    slotProps={{
                                        field:{clearable: true}
                                    }}
                                    value={date === '' ? null : dayjs(date)}
                                    onChange={(value) => setDate(dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '')}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label='Montant HT'
                                type='number'
                                value={amountHT} 
                                onChange={(e) => setHT(e.target.value)} 
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label='Montant TTC' 
                                type='number'
                                value={amountTTC}
                                onChange={(e) => setTTC(e.target.value)} 
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField label='Investissement HT'
                                type='number'
                                defaultValue={investment} 
                                onChange={(e) => setInvestment(e.target.value)} 
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='outlined' color='primary' onClick={addDuedate} disabled={props.blockDuedates}><AddCircleOutlineIcon sx={{fontSize: '1.2em', marginRight: '.25em'}} />Ajouter</Button>
                        </Grid>
                    </Grid>

                    <Grid container columnGap={2} sx={{ mt:4 }} justifyContent='space-between' alignItems='flex-end'>
                        <Grid item xs={6}>
                            <Typography fontWeight={700}>Montant restant à répartir : <Box component='span' color='secondary.dark' whiteSpace='nowrap'>{props.remainingAmount.toNumber()}€ HT</Box></Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Button variant='outlined' color='secondary' onClick={e => setBulkAdd(true)} disabled={props.blockDuedates}><AutoFixHighIcon sx={{fontSize: '1.5em', marginRight: '.25em'}} />Ajout multiple auto</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <DuedateEditDialog 
                duedate={duedateToEdit}
                setDuedate={setDuedateToEdit}
                editDuedate={editDuedate}
                conversionRate={props.conversionRate}
            />

            <DuedateBulkAddDialog
                open={bulkAdd}
                setOpen={setBulkAdd}
                onBulkAdd={addBulkDuedate}
                amount={props.remainingAmount}
                conversionRate={props.conversionRate}
                idCount={idCount}
            />

            <DeletePopup />
        </Box>
    );

    //Event handlers

    //Function used in component <DuedateBulkAddDialog/> for bulk add 
    function addBulkDuedate(duedates: ApiLink.Duedate[]) {
        if (duedates.length > 0) {
            duedates.forEach(duedate => {
                duedate.clientfile_id = props.clientfile ? props.clientfile.id : 0;
            });
            
            props.onDuedatesAddition(duedates);
            setIdCount(duedates[duedates.length - 1].id - 1)
            console.log('added duedates from bulk add : ', duedates);
        }
    }

    function addDuedate(event: React.MouseEvent) {
        event.preventDefault();
        if (date === '' || amountHT === '' || amountTTC === '' || investment === '') {
            props.setAlertText('Impossible d\'ajouter une échéance (tous les champs sont obligatoires)');
            return;
        }

        console.log('adding duedate');

        const duedate: ApiLink.Duedate = {
            id: idCount,
            date: date,
            amount_ht: Number(amountHT),
            amount_ttc: Number(amountTTC),
            investment: Number(investment),
            clientfile_id: props.clientfile ? props.clientfile.id : 0,
            toUpdate: true
        }

        props.onDuedatesAddition([duedate]);

        setIdCount(idCount - 1);
    }

    function editDuedate(event: React.FormEvent, duedate: ApiLink.Duedate) {
        event.preventDefault();
        event.stopPropagation();

        console.log('edit duedate : ', duedate);
        props.onDuedateUpdate(duedate);
    }

    function deleteDuedate(event: React.MouseEvent, duedate: ApiLink.Duedate) {
        event.preventDefault();
        event.stopPropagation();

        console.log('delete duedate : ', duedate);
        props.onDuedateDelete(duedate);
    }

    //Inner components

    function DeletePopup(props: { }) : React.ReactElement | null {
        if (duedateToDelete) {
            return (
                <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Suppression d'échéance"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Voulez-vous supprimer l'échéance à la date : {dayjs(duedateToDelete.date).format('DD/MM/YYYY')} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => setDuedateToDelete(undefined)} autoFocus>Annuler</Button>
                    <Button onClick={e => {
                        setDuedateToDelete(undefined);
                        deleteDuedate(e, duedateToDelete);
                    }}
                    variant='contained' color='secondary'
                    >
                    OK
                    </Button>
                </DialogActions>
                </Dialog>
            );
        }
        return null;
    }
};

export default DuedatePanel;