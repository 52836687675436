import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import { routes } from "../routes";
import { User } from "../link/ApiLink";

type Props = {
    user: User
}

function AppLayout({ user } : Props) {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {routes}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppLayout;