import { ReactNode } from 'react';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import FolderIcon from '@mui/icons-material/Folder';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

import HomePage from "../pages/home/HomePage";
import ClientsPage from "../pages/clients/ClientsPage";
import HistoryPage from '../pages/clientfile/HistoryPage';
import ClientfilePageLayout from '../pages/clientfile/ClientfilePageLayout';
import OngoingPage from '../pages/clientfile/OngoingPage';
import ClientfilePage from '../pages/clientfile/ClientfilePage';
import PaymentPageLayout from '../pages/payment/PaymentPageLayout';
import PaymentListPage from '../pages/payment/PaymentListPage';
import PaymentRecapPage from '../pages/payment/PaymentRecapPage';

export type RouteState = {
    menu: string,
    sidebarProps?: {
        displayText: string
    }
    pageProps?: {
        title: string
    }
}

export type RouteDef = {
    index?: Boolean,
    unique?: Boolean,
    path?: string,
    icon?: ReactNode,
    element: ReactNode,
    state: RouteState,
    child?: RouteDef[]
};

const appRoutes: RouteDef[] = [
    {
        index: true,
        element: <HomePage />,
        state: {
            menu: "home",
            pageProps: {
                title: 'Accueil',
            }
        }
    },
    {
        path: "/clientfiles",
        element: <ClientfilePageLayout />,
        state: {
            menu: "clientfiles",
            sidebarProps: {
                displayText: "Dossiers"
            },
            pageProps: {
                title: 'Dossiers',
            }
        },
        icon: <FolderIcon />,
        child: [
            {
                path: "/clientfiles/ongoing",
                element: <OngoingPage />,
                state: {
                    menu: "clientfiles.ongoing",
                    sidebarProps: {
                        displayText: "En cours"
                    },
                    pageProps: {
                        title: 'Dossiers - En cours',
                    }
                },
            },
            {
                path: "/clientfiles/history",
                element: <HistoryPage />,
                state: {
                    menu: "clientfiles.history",
                    sidebarProps: {
                        displayText: "Historique"
                    },
                    pageProps: {
                        title: 'Dossiers - Historique',
                    }
                },
            },
            {
                path: "/clientfiles/file/:clientfileId?",
                element: <ClientfilePage />,
                state: {
                    menu: "clientfiles.file",
                    pageProps: {
                        title: 'Dossiers - Modification',
                    }
                },
            },
        ]
    },
    {
        path: "/payments",
        element: <PaymentPageLayout />,
        state: {
            menu: "payments",
            sidebarProps: {
                displayText: "Règlements"
            },
            pageProps: {
                title: 'Règlements',
            }
        },
        icon: <EuroSymbolIcon />,
        child: [
            {
                path: "/payments/list",
                element: <PaymentListPage />,
                state: {
                    menu: "payments.list",
                    sidebarProps: {
                        displayText: "Liste"
                    },
                    pageProps: {
                        title: 'Règlements - Liste',
                    }
                },
            },
            {
                path: "/payments/recap",
                element: <PaymentRecapPage />,
                state: {
                    menu: "payments.recap",
                    sidebarProps: {
                        displayText: "Récapitulatif"
                    },
                    pageProps: {
                        title: 'Règlements - Récapitulatif',
                    }
                },
            }
        ]
    },
    {
        path: "/clients",
        element: <ClientsPage />,
        unique: true,
        state: {
            menu: "clients",
            sidebarProps: {
                displayText: "Clients"
            },
            pageProps: {
                title: "Clients"
            }
        },
        icon: <SupervisedUserCircleIcon />
    },
];

export default appRoutes;