import { Container, Typography } from '@mui/material';
import React from 'react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

type Props = {};

const HomePage = (props: Props) => {
    return (
        <Container>
            <Typography variant='h3' sx={{ textAlign: 'center' }}>
                Bienvenue sur l'application ! <EmojiEmotionsIcon sx={{ fontSize: '1em' }} />
            </Typography>
        </Container>
    );
};

export default HomePage;