import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as ApiLink from '../../link/ApiLink';
import { Box, Button, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';

type Props = {
    salesmen: ApiLink.User[],
    applyFilter: (s: string|null, sd: string|null, ed: string|null) => void,
    startDate: string|undefined,
    endDate: string|undefined,
    salesmanLoggedIn: string|undefined,
    setShowFilterInfos: any
};

const PaymentFilter = (props : Props) => {
    //States
    const [ salesman, setSalesman ] = React.useState<string|null>(null);
    const [ startDate, setStartDate ] = React.useState<string|null>(null);
    const [ endDate, setEndDate ] = React.useState<string|null>(null);

    //Layout
    return (
        <Box component={'form'} className='filter-form'
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                props.applyFilter(salesman, startDate, endDate);
            }}
            sx={{ 
                px :0, '@media (min-width: 600px)': { padding: "0"},
                '@media (min-width: 1200px)':{width: '100%'},
                '& .filter-form': {
                    width: '100%',
                    margin: '0',
                    padding: 0,
                },
            }}
        >
            <Stack direction='row' useFlexGap spacing={2} flexWrap='wrap' alignItems='flex-end'>
                <TextField
                    fullWidth
                    name='salesman'
                    label="Commercial" 
                    value={props.salesmanLoggedIn ? props.salesmanLoggedIn : salesman ?? ''}
                    onChange={e => setSalesman(e.target.value === '' ? null : e.target.value)}
                    select
                    disabled={props.salesmanLoggedIn ? true : false}
                    sx={{ width: 200 }}
                >
                    <MenuItem key="all" value=""><Typography component="em">Choisir un commercial</Typography></MenuItem>
                    {props.salesmen.map((salesman) => {
                        return (
                            <MenuItem key={salesman.id} value={salesman.id}>{salesman.name}</MenuItem>
                        );
                    })}
                </TextField>
                <Stack direction='row' useFlexGap spacing={2} flexWrap='nowrap' alignItems='flex-end'>
                    <Typography>Période du&nbsp;: </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            sx={{ width: 120 }}
                            value={startDate}
                            label={props.startDate ? dayjs(props.startDate).format('MM/YYYY') : 'Date de début'}
                            format='MM/YYYY'
                            slotProps={{
                                field:{clearable: true}
                            }}
                            views={['month', 'year']}
                            onChange={(value: string | null) => setStartDate(value == null ? null : dayjs(value).format('YYYY-MM-DD'))}
                        />
                    </LocalizationProvider>
                    <Typography>jusqu'au&nbsp;: </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            sx={{ width: 120 }}
                            value={endDate}
                            label={props.endDate ? dayjs(props.endDate).format('MM/YYYY') : 'Date de fin'}
                            format='MM/YYYY'
                            slotProps={{
                                field:{clearable: true}
                            }}
                            views={['month', 'year']}
                            onChange={(value: string | null) => setEndDate(value == null ? null : dayjs(value).format('YYYY-MM-DD'))}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack direction='row' flexGrow='1' justifyContent='right' flexWrap='nowrap'>
                    <Button type="submit" variant='contained' color='primary' sx={{width:'fit-content', height:'fit-content'}} startIcon={<SearchIcon />}>Appliquer</Button>
                    <IconButton color='primary' onClick={e => props.setShowFilterInfos(true)}><InfoIcon/></IconButton>
                </Stack>
            </Stack>
        </Box>
    );
}

export default PaymentFilter;