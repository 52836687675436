import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RouteDef } from "../../routes/appRoutes";
import { RootState } from "../../redux/store";

type Props = {
  item: RouteDef
}

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const { menuState } = useSelector((state : RootState) => state.menuPage);

  useEffect(() => {
    if (menuState  && menuState.menu 
                  && menuState.menu.includes(item.state.menu)) {
      setOpen(true);
    }
  }, [menuState, item]);

  return (
    item.state && item.state.sidebarProps ? (
      <>
        <ListItemButton
          onClick={() => setOpen(!open)}
          sx={{
            "&: hover": {
              backgroundColor: 'action.hover'
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: '2.5em', color: 'inherit' }}>
            {item.icon && item.icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography>
                {item.state.sidebarProps.displayText}
              </Typography>
            }
          />
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List>
            {item.child?.map((itemChild, index) => (
              itemChild.state && itemChild.state.sidebarProps ? (
                itemChild.child ? (
                  <SidebarItemCollapse item={itemChild} key={index} />
                ) : (
                  <SidebarItem item={itemChild} key={index} />
                )
              ) : null
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SidebarItemCollapse;