import React, { ChangeEvent } from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Box, Backdrop, CircularProgress } from '@mui/material';
import { enqueueSnacks } from '../common/CustomSnackbar';

type Props = {
    open: boolean,
    setOpen: any,
    reloadFunction: () => void,
    setAlertText: any
};

const ClientAddDialog = (props : Props) => {
    //States
    const [ client, setClient ] = React.useState<ApiLink.Client>({id: 0, company_name: '', contact: ''});
    const [ loading, setLoading ] = React.useState<boolean>(false);

    //Layout
    if (props.open) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <Box component='form' onSubmit={e => {
                    e.preventDefault();
                    insertClient(e);
                }}>
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion de client"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Ajouter un client
                        </DialogContentText>
                        <TextField id="company" name="company" label="Société" variant="standard" sx={{mt: '8px'}} defaultValue={client.company_name} onChange={handleEditChange} fullWidth required />
                        <TextField id="contact" name="contact" label="Contact" variant="standard" sx={{mt: '8px'}} defaultValue={client.contact} onChange={handleEditChange} fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => { resetClient(); props.setOpen(false); }} autoFocus>Annuler</Button>
                        <Button type='submit' variant='contained' color='secondary'>Ajouter</Button>
                    </DialogActions>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        );
    }

    return null;

    //Event handlers

    function handleEditChange(e: ChangeEvent) {
        const {name, value} = e.target as HTMLInputElement;
        if (client) {
            switch (name) {
            case "contact":
                setClient({...client, contact: value});
                break;
            case "company":
                setClient({...client, company_name: value});
                break;
            default:
                break;
            }
        }
    }

    function insertClient(event: React.FormEvent) {
        if (client) {
            setLoading(true);
            ApiLink.insertClient(client)
            .then(() => {
                enqueueSnacks("Ajout réussi");
                props.reloadFunction();
            })
            .catch((err) => {
                props.setAlertText(err.message);
                enqueueSnacks(undefined, undefined, "Erreur d'ajout du client");
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
                props.setOpen(false);
            });
            
            resetClient();
        }
    }

    function resetClient() {
        setClient({...client, id: 0, company_name: '', contact: ''});
    }
};

export default ClientAddDialog;