import React, { ChangeEvent } from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Box, Backdrop, CircularProgress } from '@mui/material';
import { enqueueSnacks } from '../common/CustomSnackbar';

type Props = {
    client: ApiLink.Client | undefined,
    setClient: any,
    reloadFunction: () => void,
    setAlertText: any
};

const ClientEditDialog = (props : Props) => {
    //States
    const [ client, setClient ] = React.useState<ApiLink.Client>();
    const [ loading, setLoading ] = React.useState<boolean>(false);

    //Load
    React.useEffect(() => {
        setClient(props.client);
    }, [props]);

    //Layout
    if (props.client) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <Box component='form' onSubmit={e => {
                    e.preventDefault();
                    editSelectedClient(e);
                }}>
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion de client"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Modifier le client sélectionné
                        </DialogContentText>
                        <TextField id="company" name="company" label="Société" variant="standard" sx={{mt: '8px'}} value={client ? client.company_name : ""} onChange={handleEditChange} fullWidth required />
                        <TextField id="contact" name="contact" label="Contact" variant="standard" sx={{mt: '8px'}} value={client ? client.contact : ""} onChange={handleEditChange} fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => props.setClient(undefined)} autoFocus>Annuler</Button>
                        <Button type='submit' variant='contained' color='primary'>Modifier</Button>
                    </DialogActions>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        );
    }
    
    return null;

    //Event handlers

    function handleEditChange(e: ChangeEvent) {
        const {name, value} = e.target as HTMLInputElement;
        if (client) {
            switch (name) {
            case "contact":
                setClient({...client, contact: value});
                break;
            case "company":
                setClient({...client, company_name: value});
                break;
            default:
                break;
            }
        }
    }

    async function editSelectedClient(event: React.FormEvent) {
        if (client) {
            setLoading(true);
            ApiLink.updateClient(client)
            .then(() => {
                enqueueSnacks("Modification réussie");
                props.reloadFunction();
            })
            .catch((err) => {
                props.setAlertText(err.message);
                enqueueSnacks(undefined, undefined, "Erreur de modification du client");
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
                props.setClient(undefined);
            });
        }
    }
};

export default ClientEditDialog;