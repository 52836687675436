import { User } from "../link/ApiLink";

/**
 * Generate a user name string from a user object, using its first and last names, and
 * its login if necessary
 * @param user 
 * @returns 
 */
export function userName(user: User): string {
	let uText = '';
	if (user) {
		uText = user.name?.trim();
		if (!uText) {
			uText = user.username;
		}
	}
	return uText;
}