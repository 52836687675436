import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js-light';

type Props = {
    setAlertText: any,
    invoiceState: any,
    setInvoiceState: any,
    onProductionCostChange: any,
    amountHT: string,
    amountTTC: string,
    setAmountHT: any,
    setAmountTTC: any,
    blockAmount: boolean,
    blockDuedates: boolean,
    duedateRequired: boolean,
    conversionRate: number
};

const InvoiceFields = (props : Props) => {
    //States
    const setHT = (value: string) => {
        props.setAmountHT(value);

        if (value === '') {
            props.setAmountTTC('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.mul(props.conversionRate);
            props.setAmountTTC(nv.toDecimalPlaces(2).toString());
        }
    };

    const setTTC = (value: string) => {
        props.setAmountTTC(value);

        if (value === '') {
            props.setAmountHT('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.dividedBy(props.conversionRate)
            props.setAmountHT(nv.toDecimalPlaces(2).toString());
        }
    };

    //Load
    React.useEffect(() => {

    }, []);

    //Layout
    return (
        <Box className='invoice'>
            <Typography variant='h5' component='h2'>Facture</Typography>

            <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt:2 }}>
                <Grid item xs={6}>
                    <TextField label='N°'
                        inputProps={{ maxLength: 45 }}
                        fullWidth
                        value={props.invoiceState.number} 
                        onChange={(e) => props.setInvoiceState({...props.invoiceState, number: e.target.value})} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            label="Émise le"
                            format='DD/MM/YYYY'
                            slotProps={{
                                field:{clearable: true},
                                textField:{required: props.duedateRequired}
                            }}
                            
                            value={props.invoiceState.date ? dayjs(props.invoiceState.date) : null}
                            onChange={(value) => props.setInvoiceState({...props.invoiceState, date: value == null ? null : dayjs(value).format('YYYY-MM-DD')})}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField label='Libelle'
                        inputProps={{ maxLength: 160 }}
                        fullWidth
                        value={props.invoiceState.label}
                        onChange={(e) => props.setInvoiceState({...props.invoiceState, label: e.target.value})} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField label='Montant HT'
                        type='number'
                        fullWidth
                        disabled={props.blockAmount || props.blockDuedates}
                        value={props.amountHT}
                        onChange={(e) => setHT(e.target.value)} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField label='Montant TTC' 
                        type='number'
                        fullWidth
                        disabled={props.blockAmount || props.blockDuedates}
                        value={props.amountTTC}
                        onChange={(e) => setTTC(e.target.value)} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField label='Coût de fabrication initial HT'
                        type='number'
                        fullWidth
                        disabled={props.blockDuedates}
                        value={props.invoiceState.productionCost} 
                        onChange={(e) =>  {
                            props.setInvoiceState({...props.invoiceState, productionCost: e.target.value});
                            props.onProductionCostChange(true);
                        }} 
                    />
                </Grid>
            </Grid>
            
            
            
            
            
            
        </Box>
    );
};

export default InvoiceFields;