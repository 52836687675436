import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Toolbar } from '@mui/material';
import { DataGrid, GridColDef, GridDeleteIcon, GridRowId } from '@mui/x-data-grid';
import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ClientAddDialog from '../../components/clients/ClientAddDialog';
import ClientEditDialog from '../../components/clients/ClientEditDialog';
import CustomSnackbar, { enqueueSnacks } from '../../components/common/CustomSnackbar';
import sizeConfigs from '../../configs/sizeConfigs';
import * as ApiLink from '../../link/ApiLink';
import { LoginState } from '../../redux/features/loginSlice';

type Props = {};

const ClientsPage = (props: Props) => {
    //States
    const { user } = useSelector((state: { login: LoginState }) => state.login);
    const initState: { clients: ApiLink.Client[], loading: boolean, loadError?: string } = { clients: [], loading: true, loadError: undefined };
    const [clientsState, setClientsState] = React.useState(initState);
    const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
    const [alertText, setAlertText] = React.useState<string>();
    const [deleteConfirmText, setDeleteConfirmText] = React.useState<string>();
    const [modifiedClient, setModifiedClient] = React.useState<ApiLink.Client>();
    const [openAddDialog, setOpenAddDialog] = React.useState<boolean>(false);

    const reloadClients = () => {
        setClientsState({ ...clientsState, loading: true});
    };

    //Load
    React.useEffect(() => {
        if (clientsState.loading) {
            ApiLink.getAllClients()
                .then(clients => {
                    if (!clients) clients = [];
                    setClientsState({ ...clientsState, clients: clients, loading: false });
                })
                .catch(err => setClientsState({ ...clientsState, loading: false, loadError: "" + err }));
        }
    }, [setClientsState, clientsState]);

    if (!user || user.auth_group === ApiLink.AuthGroup.SALESMAN) {
        return <Navigate to="/" replace />;
    }

    //Grid Columns
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'company_name',
            headerName: 'Société',
            width: 250,
            renderCell: (params) => {
                return (
                    <Link   onClick={e => setModifiedClient(params.row)} 
                            variant='inherit'
                            className='MuiDataGrid-cellContent'>
                        {params.row.company_name}
                    </Link>
                );
            }
        },
        {
            field: 'contact',
            headerName: 'Contact',
            width: 250,
            renderCell: (params) => {
                return (
                    <Link   onClick={e => setModifiedClient(params.row)} 
                            variant='inherit'
                            className='MuiDataGrid-cellContent'>
                        {params.row.contact}
                    </Link>
                );
            }
        },
    ];

    //Layout
    return (
        <Box>
            {clientsState.loadError && <Alert severity='error' >{clientsState.loadError}</Alert>}
            <Container sx={{width:'45rem', maxWidth: `calc(100vw - 32px - ${sizeConfigs.sidebar.width})`, px :0, '@media (min-width: 600px)': { padding: "0"},
            '@media (min-width: 1200px)':{maxWidth: `calc(100vw - 32px - ${sizeConfigs.sidebar.width})`}}}
            >
                <Box sx={{height: '32.4rem', px: 0, '@media (min-width: 600px)': { padding: "0"}}}>
                    <DataGrid
                        sx={{
                            cursor: 'default',
                            '& .MuiDataGrid-row a': {
                                textDecoration: 'none',
                                color: 'inherit'
                            },
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: '#f0634220',
                                '& a': {
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                },
                            },
                        }}
                        rows={clientsState.clients}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        checkboxSelection
                        loading={clientsState.loading}
                        disableRowSelectionOnClick={true}
                        onRowSelectionModelChange={(selectionModel, details) => {
                            setSelectedRows(selectionModel);
                        }}
                        onRowDoubleClick={(params, event, details) => {
                            setModifiedClient(params.row);
                        }}
                        density='compact'
                    />
                </Box>
                <Toolbar sx={{ justifyContent: 'space-between', px: 0, '@media (min-width: 600px)': {padding: "0"}}}>
                    <Button variant='outlined' color='error' onClick={confirmDeleteSelectedClient} disabled={!selectedRows.length}><GridDeleteIcon sx={{ fontSize: '1.2em', marginRight: '.25em' }} /> Supprimer</Button>
                    <Button variant='contained' color='secondary' onClick={confirmAddClient}><PlaylistAddIcon sx={{ fontSize: '1.2em', marginRight: '.25em' }} /> Ajouter</Button>
                </Toolbar>
            </Container>

            <LocalAlertDialog />

            <LocalDeleteConfirmDialog onConfirm={deleteSelectedClient} />

            <ClientEditDialog client={modifiedClient} setClient={setModifiedClient} reloadFunction={reloadClients} setAlertText={setAlertText} />

            <ClientAddDialog open={openAddDialog} setOpen={setOpenAddDialog} reloadFunction={reloadClients} setAlertText={setAlertText} />

            <CustomSnackbar />
        </Box>
    );

    //Event handlers

    function confirmAddClient(event: React.MouseEvent) {
        setOpenAddDialog(true);
    }

    function confirmDeleteSelectedClient(event: React.MouseEvent) {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length > 1) {
                setDeleteConfirmText(`Supprimer les ${selectedRows.length} clients sélectionnés\u00a0?`);
            } else {
                const cid = selectedRows[0];
                const client = clientsState.clients.find(c => c.id === cid);
                setDeleteConfirmText(`Supprimer le client ${client?.company_name}\u00a0?`);
            }
        }
    }

    function deleteSelectedClient(event: React.MouseEvent) {
        if (!selectedRows || selectedRows.length < 1)
            return;

        let deleteIds: number[] = [];
        selectedRows.forEach(cid => {
            deleteIds.push(Number(cid));
        });

        console.log("Delete clients :", deleteIds);
        ApiLink.deleteClients(deleteIds)
        .then(() => {
            enqueueSnacks("Suppression réussie");
            reloadClients();
        })
        .catch((err) => {
            setAlertText(err.message);
            enqueueSnacks(undefined, undefined, "Erreur de suppression du client");
            console.error(err);
        });
    }


    //Inner components

    function LocalAlertDialog(props: {}): React.ReactElement | null {
        if (alertText) {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion des clients"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setAlertText(undefined)} autoFocus variant='contained' color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    function LocalDeleteConfirmDialog(props: { onConfirm?: MouseEventHandler }): React.ReactElement | null {
        if (deleteConfirmText) {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Suppression de clients"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {deleteConfirmText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setDeleteConfirmText(undefined)} autoFocus>Annuler</Button>
                        <Button onClick={e => {
                            setDeleteConfirmText(undefined);
                            props.onConfirm && props.onConfirm(e);
                        }}
                            variant='outlined' color='error'
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    }
};

export default ClientsPage;