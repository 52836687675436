import { setUser } from "../redux/features/loginSlice";
import { store } from "../redux/store";

/**
 * Perform a GET request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param queryParms Request parameters to be passed in URL
 * @returns 
 */
export async function get(url: string, queryParms?: string | Record<string, string> | URLSearchParams | string[][] | undefined) : Promise<Response> {
    let queryString = '';
    if (queryParms) {
        queryString = '?' + new URLSearchParams(queryParms);
    }
    const resp = await fetch(url + queryString, {
        method: 'get',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });

    if (!resp.ok) {
        //Getting the json resp for the error
        const errorJson = await resp.json();
        //If 403 then AccessDeniedError
        if (resp.status === 403) {
            throw new AccessDeniedError(`Accès refusé par le serveur`);
        } 
        //If 401 then update the user in redux state and NotLoggedInError
        else if (resp.status === 401) {
            store.dispatch(setUser(undefined));
            throw new NotLoggedInError(`Accès refusé (utilisateur non connecté)`);
        } 
        //If other status and json is the custom json send from CI then Error
        else if (errorJson.error) {
            console.error(url, errorJson);
            let errorString: string = errorJson.error;
            if (errorJson.warnings && errorJson.warnings.length > 0) {
                errorString += " : ";
                let warningString = errorJson.warnings.join(' / ');
                errorString += warningString;
            }
            throw new Error(errorString);
        } 
        //If other status and json is the default json then Error
        else {
            console.error(url, errorJson.message);
            throw new Error(`Erreur de communication avec le serveur ! Code : ${errorJson.code} | Erreur : ${errorJson.message}`);
        }
    }

    return resp;
}

/**
 * Perform a POST request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param body Object to be passed as request body, encoded into JSON
 * @returns 
 */
export async function post(url: string, body?: object) : Promise<Response> {
    const resp = await fetch(url, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'include'
    });

    if (!resp.ok) {
        //Getting the json resp for the error
        const errorJson = await resp.json();
        //If 403 then AccessDeniedError
        if (resp.status === 403) {
            throw new AccessDeniedError(`Accès refusé par le serveur`);
        } 
        //If 401 then update the user in redux state and NotLoggedInError
        else if (resp.status === 401) {
            store.dispatch(setUser(undefined));
            throw new NotLoggedInError(`Accès refusé (utilisateur non connecté)`);
        } 
        //If other status and json is the custom json send from CI then Error
        else if (errorJson.error) {
            console.error(url, errorJson);
            let errorString: string = errorJson.error;
            if (errorJson.warnings && errorJson.warnings.length > 0) {
                errorString += " : ";
                let warningString = errorJson.warnings.join(' / ');
                errorString += warningString;
            }
            throw new Error(errorString);
        } 
        //If other status and json is the default json then Error
        else {
            console.error(url, errorJson.message);
            throw new Error(`Erreur de communication avec le serveur ! Code : ${errorJson.code} | Erreur : ${errorJson.message}`);
        }
    }

    return resp;
}

export class AccessDeniedError extends Error {};
export class NotLoggedInError extends Error {};
