import React, { FormEvent, RefCallback } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Box } from '@mui/material';

import * as ApiLink from '../link/ApiLink';

const NO_LOGIN_MESSAGE = 'Utilisateur/mot de passe inconnu';

type Props = {
    onLoggedIn: RefCallback<ApiLink.User>
};

function LoginForm({ onLoggedIn } : Props) {
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [working, setWorking] = React.useState(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setWorking(true);

        ApiLink.login(userName, password)
        .then(user => {
            setPassword('');
            if (!user) {
                window.alert(NO_LOGIN_MESSAGE);
                return;
            }
            setUserName('');

            if (onLoggedIn) {
                onLoggedIn(user);
            }
        })
        .catch(reason => {
            console.error(reason);
            window.alert(reason);
        })
        .finally(() => setWorking(false));
    };

    return (
        <Dialog open={true} className='LoginForm-dialog'>
            <Box component="form" onSubmit={handleSubmit} className="LoginForm">
                <DialogTitle>Connexion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Entrez vos informations de connexion
                    </DialogContentText>
                    <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Nom d'utilisateur&nbsp;:"
                        variant="standard"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        required={true}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        id="password"
                        label="Mot de passe&nbsp;:"
                        variant="standard"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required={true}

                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="secondary" variant="contained" disabled={working}>Connexion</Button>
                </DialogActions>
            </Box>
        </Dialog>
      );

}

export default LoginForm;