import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { MenuItem, Button , TextField, Typography, Box, Autocomplete, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

type Props = {
    user: ApiLink.User|undefined,
    onSubmit: (clientId: string|null, salesmanId: string|null, startDate: string|null, endDate: string|null) => void
};

const ClientFilesFilter = (props : Props) => {
    //States
    const [ clients, setClients ] = React.useState<ApiLink.Client[]>([]);
    const [ salesmen, setSalesmen ] = React.useState<ApiLink.User[]>([]);
    const [ selectedClient, setSelectedClient ] = React.useState<string|null>(null);
    const [ selectedSalesman, setSelectedSalesman ] = React.useState<string|null>(null);
    const [ startDate, setStartDate ] = React.useState<string|null>(null);
    const [ endDate, setEndDate ] = React.useState<string|null>(null);
    const [ clientOptions, setClientOptions ] = React.useState<string[]>([]);
    const [ clientInputValue, setClientInputValue ] = React.useState<string>();

    const clientAutocomplete = React.useCallback(
        (event: React.SyntheticEvent, value: string, reason: string) => {
            setClientInputValue(value);
            const client = clients.find((element) => element.company_name === value);
            setSelectedClient(client ? client.id.toString() : null);
    }, [clients]);

    //Load
    React.useEffect(() => {
        ApiLink.getSalesmen()
        .then(salesmen => {
            if (!salesmen) salesmen = [];
            setSalesmen(salesmen);
        })
        .catch(err => setSalesmen([]));

        ApiLink.getAllClients()
        .then(clients => {
            if (!clients) clients = [];
            setClients(clients);
            
            //Add client option for autocompletion
            let clientOptions: string[] = [];
            clients.forEach(element => {
                clientOptions.push(element.company_name);
            });
            setClientOptions(clientOptions);
        })
        .catch(err => setClients([]));
    }, [setSalesmen, setClients]);

    React.useEffect(() => {
        if (props.user && props.user.auth_group === ApiLink.AuthGroup.SALESMAN) {
            setSelectedSalesman(props.user.id.toString());
        }
    }, [props.user])

    //Layout
    return (
        <Box sx={{ px :0, '@media (min-width: 600px)': { padding: "0"},
                '@media (min-width: 1200px)':{width: '100%'},
                '& .filter-form': {
                    width: '100%',
                    margin: '0',
                    padding: 0,
                },
            }}
         >
            <Stack direction='row' useFlexGap spacing={2} component="form"
                onSubmit={e => { e.preventDefault(); props.onSubmit(selectedClient, selectedSalesman, startDate, endDate);}}
            >
                <Typography variant='h6' width='auto' marginTop={2}>Recherche&nbsp;: </Typography>
                <Stack direction='row' useFlexGap rowGap={1} columnGap={2} flexWrap={'wrap'} alignItems={'flex-end'} flexGrow='1'>
                    <Stack direction='row' useFlexGap spacing={2} flexWrap='nowrap'>
                        <Autocomplete
                            options={clientInputValue ? clientOptions : clientOptions.slice(-20)}
                            freeSolo
                            defaultValue={''}
                            renderInput={(params) => <TextField {...params} label="Client" />}
                            onInputChange={clientAutocomplete}
                            sx={{ width: 210 }}
                        />
                        <TextField name="salesman" label="Commercial" select fullWidth
                            value={selectedSalesman ?? ''}
                            onChange={e => setSelectedSalesman(e.target.value === '' ? null : e.target.value)}
                            disabled={props.user && props.user.auth_group === ApiLink.AuthGroup.SALESMAN ? true : false}
                            sx={{ width: 100 }}
                        >
                            <MenuItem key="all" value="">
                                <Typography component="em">Tout le monde</Typography>
                            </MenuItem>
                            {salesmen.map((salesman) => {
                                return (
                                    <MenuItem key={salesman.id} value={salesman.id}>{salesman.name}</MenuItem>
                                );
                            })}
                        </TextField>
                    </Stack>
                    <Stack direction='row' useFlexGap spacing={2} flexWrap='nowrap'>
                        <Typography whiteSpace='nowrap'>Période du&nbsp;:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                <DatePicker
                                    sx={{ width: 130 }}
                                    format='DD/MM/YYYY'
                                    value={startDate}
                                    slotProps={{ 
                                        textField: { /* placeholder: dayjs(startDate).format('DD/MM/YYYY') */} }}
                                    onChange={value => {
                                        setStartDate(value == null ? null : dayjs(value).format('YYYY-MM-DD'));
                                    }}
                                />
                            </LocalizationProvider>
                        <Typography>au&nbsp;:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                            <DatePicker
                                sx={{ width: 130 }}
                                format='DD/MM/YYYY'
                                value={endDate}
                                slotProps={{ textField: { /* placeholder: dayjs(endDate).format('DD/MM/YYYY') */ } }}
                                onChange={(value: string | null) => setEndDate(value == null ? null : dayjs(value).format('YYYY-MM-DD'))}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Stack direction='row' flexGrow={1} justifyContent='right'>
                        <Button className='submit' type='submit' variant='contained' color='primary' startIcon={<SearchIcon />} sx={{width:'fit-content'}}>
                            Rechercher
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ClientFilesFilter;