import * as ApiLink from '../../link/ApiLink';
import { Avatar, Divider, Drawer, List, Stack, Toolbar, Typography } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import logo from '../../assets/images/logo-sudmedia.png';
import * as appInfo from '../../configs/appInfo';
import { useSelector } from "react-redux";
import { LoginState } from "../../redux/features/loginSlice";


const Sidebar = () => {
    const { user } = useSelector((state: { login: LoginState }) => state.login);
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: sizeConfigs.sidebar.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: 'border-box',
                    bgcolor: 'primary.dark',
                    color: 'primary.contrastText'
                }
            }}
        >
            <Toolbar >
                <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={theme => theme.spacing(2)}
                >
                    <Avatar variant="square" src={logo} sx={{ backgroundColor: "none", width: '3rem', height: '3rem' }} />
                    <Typography variant="subtitle1" sx={{ lineHeight: '1.2em' }}>{appInfo.shortName}</Typography>
                    <Typography>{appInfo.version}</Typography>
                </Stack>
            </Toolbar>
            <Divider />
            <List disablePadding>
                {
                    appRoutes.map((route, index) => {
                        //Don't display route with path /clients if connected user is salesman
                        if (user && user.auth_group === ApiLink.AuthGroup.SALESMAN && route.path === '/clients') {
                            return null;
                        }
                        if (route.state && route.state.sidebarProps && !route.unique) {
                            return (<SidebarItemCollapse item={route} key={index} />);
                        } else if (route.child || route.unique) {
                            return (<SidebarItem item={route} key={index} />);
                        } else {
                            return null;
                        }
                    })
                }
            </List>
        </Drawer>
    );
};

export default Sidebar;