import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Box, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { enqueueSnacks } from '../common/CustomSnackbar';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js-light';

type Props = {
    open: boolean,
    setOpen: any,
    salesmen: ApiLink.User[],
    setReloadPayments: any,
    conversionRate: number,
    setAlertText: any
};

const PaymentAddDialog = (props : Props) => {
    //States
    const [ payment, setPayment ] = React.useState<ApiLink.Payment>({id: 0, date: '', amount_ht: null, amount_ttc: null, users_id: 0, user: {} as ApiLink.User});
    const [ amountHT, setAmountHT ] = React.useState<string>('');
    const [ amountTTC, setAmountTTC ] = React.useState<string>('');
    const [ loading, setLoading ] = React.useState<boolean>(false);

    const setHT = (value: string) => {
        setAmountHT(value);

        if (value === '') {
            setAmountTTC('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.mul(props.conversionRate);
            setAmountTTC(nv.toDecimalPlaces(2).toString());
        }
    };

    const setTTC = (value: string) => {
        setAmountTTC(value);

        if (value === '') {
            setAmountHT('');
        } else {
            let v = new Decimal(Number(value));
            let nv = v.dividedBy(props.conversionRate)
            setAmountHT(nv.toDecimalPlaces(2).toString());
        }
    };

    //Load
    React.useEffect(() => {

    }, []);

    //Update payment when amountHT or amountTTC change
    React.useEffect(() => {
        setPayment(payment => {
            payment.amount_ht = Number(amountHT);
            payment.amount_ttc = Number(amountTTC);
            return payment;
        });
    }, [amountHT, amountTTC])

    //Layout
    if (props.open) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <Box component='form' onSubmit={e => {
                    e.preventDefault();
                    insertPayment(e);
                }}>
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion des règlements"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Ajouter un règlement
                        </DialogContentText>
                        <TextField
                            fullWidth
                            label="Commercial" 
                            defaultValue=""
                            onChange={e => setPayment({...payment, users_id: Number(e.target.value)})}
                            select
                            required
                        >
                            <MenuItem key="all" value=""><em>Choisir un commercial</em></MenuItem>
                            {props.salesmen.map((salesman) => {
                                return (
                                    <MenuItem key={salesman.id} value={salesman.id}>{salesman.name}</MenuItem>
                                );
                            })}
                        </TextField>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                            <DatePicker
                                sx={{mt: '8px'}}
                                label="Date de règlement"
                                format='DD/MM/YYYY'
                                slotProps={{
                                    field:{clearable: true},
                                    textField: {
                                        required: true,
                                    }
                                }}
                                value={payment.date === '' ? null : dayjs(payment.date)}
                                onChange={(value) => setPayment({...payment, date: dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : ''})}
                            />
                        </LocalizationProvider>
                        <TextField name="amountHT" label="Montant HT" type='number' sx={{mt: '8px'}} fullWidth required
                            value={amountHT} 
                            onChange={e => setHT(e.target.value)} 
                        />
                        <TextField name="amountTTC" label="Montant TTC" type='number' sx={{mt: '8px'}} fullWidth required
                            value={amountTTC} 
                            onChange={e => setTTC(e.target.value)} 
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(_e) => {
                            props.setOpen(false)
                            clearForm();
                        }} autoFocus>Annuler</Button>
                        <Button type='submit' variant='contained' color='secondary'>Ajouter</Button>
                    </DialogActions>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        );
    }

    return null;

    //Event handlers

    function insertPayment(event: React.FormEvent) {
        if (payment) {
            setLoading(true);
            ApiLink.insertPayment(payment)
            .then(() => {
                enqueueSnacks("Ajout réussi");
                props.setReloadPayments(true);
            })
            .catch((err) => {
                props.setAlertText(err.message);
                enqueueSnacks(undefined, undefined, "Erreur d'ajout du paiement");
            })
            .finally(() => {
                setLoading(false);
                props.setOpen(false);
            });
            
            clearForm();
        }
    }

    function clearForm() {
        setAmountHT('');
        setAmountTTC('');
        setPayment({...payment, id: 0, date: '', amount_ht: null, amount_ttc: null, users_id: 0, user: {} as ApiLink.User});
    }
};

export default PaymentAddDialog;