import { AppBar, Avatar, Divider, IconButton, Menu, Stack, Toolbar, Typography } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import { useDispatch, useSelector } from "react-redux";
import { userName } from '../../util/DataUtil';
import { stringAvatar } from "../../util/UiUtil";
import { MouseEvent, useState } from "react";
import { setUser } from "../../redux/features/loginSlice";
import LogoutItem from "../usermenu/LogoutItem";
import { RootState } from "../../redux/store";

type Props = {
  title: string
}

const Topbar = ({ title } : Props) => {
  const dispatch = useDispatch();

  //Menu show/hide
  const [anchorEl, setAnchorEl] = useState<Element|null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget as Element);
  };
  const handleClose = (event: MouseEvent) => {
    setAnchorEl(null);
  };

  //Logout click
  const handleLoggeOut = () => {
    dispatch(setUser(null));
  };

  //Avatar/Menu display
  const { user } = useSelector((state : RootState) => state.login);

  const noUser = user ? null : <Avatar />
  const withUser = user && <>
          <IconButton onClick={handleClick}><Avatar {...stringAvatar(userName(user))} /></IconButton>
          <Menu id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: { pt: 0 }
            }}
          >
            <Typography variant="subtitle1" color='secondary.main' sx={{textAlign: 'center', fontWeight: 'bold', mt: '0', p: 1}}>{userName(user)}</Typography>
            <Divider />
            <LogoutItem onLoggeOut={handleLoggeOut} />
          </Menu>
        </>;

  return (
    <AppBar
    position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: `${sizeConfigs.sidebar.width}`,
        boxShadow: "unset"
      }}
    >
      <Toolbar>
        <Stack direction='row' flexGrow="1" alignItems='center'>
          <Typography variant="h6" noWrap component="div" flexGrow="1">
            {title}
          </Typography>
          {noUser}
          {withUser}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;