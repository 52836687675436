import { ThemeOptions } from "@mui/material";
import type { } from '@mui/x-data-grid/themeAugmentation';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#335b66',
        },
        secondary: {
            main: '#f06342',
        },
        background: {
            default: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Inter, Arial, Sans-Serif, sansserif, sans',
    },
    components: {
        // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    },
                },
                columnHeader: {
                    '&:focus': {
                        outline: 'none'
                    },
                    '&:focus-within': {
                        outline: 'none'
                    },
                }
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            }
        }
    },
};