import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Toolbar } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomSnackbar, { enqueueSnacks } from '../../components/common/CustomSnackbar';
import PaymentAddDialog from '../../components/payments/PaymentAddDialog';
import PaymentEditDialog from '../../components/payments/PaymentEditDialog';
import PaymentFilter from '../../components/payments/PaymentFilter';
import * as ApiLink from '../../link/ApiLink';
import { LoginState } from '../../redux/features/loginSlice';
import { dateFormatter, priceFormatter } from '../../util/UiUtil';

type Props = {};

const PaymentListPage = (props: Props) => {
    //States
    const { user } = useSelector((state: { login: LoginState }) => state.login);
    const [ payments, setPayments ] = React.useState<ApiLink.Payment[]>([]);
    const [ salesmen, setSalesmen ] = React.useState<ApiLink.User[]>([]);
    const [ alertText, setAlertText ] = React.useState<string>();
    const [ openAddDialog, setOpenAddDialog ] = React.useState<boolean>(false);
    const [ conversionRate, setConversionRate ] = React.useState<number>(1);
    const [ paymentToEdit, setPaymentToEdit ] = React.useState<ApiLink.Payment>();
    const [ paymentToDelete, setPaymentToDelete ] = React.useState<ApiLink.Payment>();

    //Filter
    const initFilterState: {salesman: string|null, startDate: string|null, endDate: string|null} = { salesman: null, startDate: null, endDate: null };
    const [ filterState, setFilterState ] = React.useState(initFilterState);
    const [ reloadPayments, setReloadPayments ] = React.useState<boolean>(false);
    const [ salesmanLoggedIn, setSalesmanLoggedIn ] = React.useState<string>();

    const applyFilter = (s: string|null, sd: string|null, ed: string|null) => {
        setFilterState({...filterState, salesman: s, startDate: sd, endDate: ed});
        setReloadPayments(true);
    }

    //Load
    React.useEffect(() => {
        if (user && user.auth_group === ApiLink.AuthGroup.SALESMAN) {
            setFilterState(previous => ({...previous, salesman: user.id.toString()}));
            setSalesmanLoggedIn(user.id.toString());
        }
        setReloadPayments(true);

        //Load settings
        ApiLink.getSetting('clientfile.amount.conversion.rate')
        .then(setting => {
            if (setting) {
                setConversionRate(Number(setting.value))
            }
        })
        .catch(err => setAlertText("Une erreur est survenue : " + err.message))

        //Load salesmen
        ApiLink.getSalesmen()
        .then(salesmen => {
            if (!salesmen) salesmen = [];
            setSalesmen(salesmen);
        })
        .catch(err => setSalesmen([]));
    }, [user]);

    React.useEffect(() => {
        if (reloadPayments) {
            console.log('filtering payments with salesman_id:' + filterState.salesman + 
            ' | start_date:' + filterState.startDate + ' | end_date:' + filterState.endDate);

            ApiLink.getFilteredPayments(filterState.salesman, filterState.startDate, filterState.endDate)
            .then(payments => {
                if (!payments) payments = [];
                setPayments(payments);
            })
            .catch(err => setAlertText("Une erreur est survenue : " + err.message));

            setReloadPayments(false);
        }
    }, [reloadPayments, filterState]);

    //Grid Columns
    const columns: GridColDef[] = [
        {
        field: 'id',
        headerName: '',
        sortable: false,
        width: 50,
        align : 'center',
        renderCell: (params) => {
            let affectedByPayment = (params.row.remaining_commissions ?? 0) < (params.row.total_commissions ?? 0);
            let firstDuedatePassed = params.row.first_duedate && (params.row.first_duedate < new Date().toISOString().substring(0,10));
            let disableDeletion = affectedByPayment || firstDuedatePassed; 
            return (
                <IconButton sx={{padding:0}}
                    color='error' 
                    onClick={e => setPaymentToDelete(params.row)} 
                    disabled={disableDeletion}
                    >
                        <DeleteIcon />
                </IconButton>
            );
            }
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => {
                return (
                    <Link   onClick={e => setPaymentToEdit(params.row)}
                            sx={{ color: 'black' }}
                            className='MuiDataGrid-cellContent'>
                        {dateFormatter.format(new Date(params.row.date))}
                    </Link>
                );
            }
        },
        {
            field: 'user',
            headerName: 'Commercial',
            width: 150,
            valueGetter(params) {
                return params.row.user.name;
            },
        },
        {
            field: 'amount_ht',
            headerName: 'Réglé HT',
            width: 150,
            renderCell: (params) => {
                return (
                    <Link   onClick={e => setPaymentToEdit(params.row)}
                            className='MuiDataGrid-cellContent'>
                        {priceFormatter.format(params.row.amount_ht)}
                    </Link>
                );
            }
        },
        {
            field: 'amount_ttc',
            headerName: 'Réglé TTC',
            width: 150,
            renderCell: (params) => {
                return (
                    <Link   onClick={e => setPaymentToEdit(params.row)}
                            className='MuiDataGrid-cellContent'>
                        {priceFormatter.format(params.row.amount_ttc)}
                    </Link>
                );
            }
        },
    ];

    //Layout
    return (
        <>
            <Container sx={{maxWidth: 'fit-content', px :0, '@media (min-width: 600px)': { padding: "0"},
            '@media (min-width: 1200px)':{maxWidth: 'fit-content'}}}>
                <PaymentFilter
                    salesmen={salesmen}
                    applyFilter={applyFilter}
                    salesmanLoggedIn={salesmanLoggedIn}
                />
            </Container>
            <Container sx={{width:'fit-content', height: '35.05rem', px: 0, '@media (min-width: 600px)': { padding: "0"}}}>
                <DataGrid
                    sx={{
                        mt: 1,
                        cursor: 'default',
                        '& .MuiDataGrid-row a': {
                            textDecoration: 'none',
                            color: 'inherit'
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#f0634220',
                            '& a': {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            },
                        },
                    }}
                    rows={payments}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    rowSelection={false}
                    disableRowSelectionOnClick={true}
                    onRowDoubleClick={(params, event, details) => {
                        setPaymentToEdit(params.row);
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        }
                    }}
                    disableDensitySelector
                    density='compact'
                />
                <Toolbar sx={{ justifyContent: 'flex-end', px: 0, '@media (min-width: 600px)': {padding: "0"}}}>
                    <Button 
                    variant='contained' 
                    color='secondary' 
                    onClick={e => setOpenAddDialog(true)}
                    disabled={salesmanLoggedIn ? true : false}
                    startIcon={<AddCircleOutlineIcon /> }>
                        Ajouter
                    </Button>
                </Toolbar>
            </Container>



            <LocalAlertDialog />

            <PaymentAddDialog 
                open={openAddDialog} 
                setOpen={setOpenAddDialog}
                salesmen={salesmen}
                setReloadPayments={setReloadPayments} 
                conversionRate={conversionRate}
                setAlertText={setAlertText}
            />

            <PaymentEditDialog
                payment={paymentToEdit}
                setPayment={setPaymentToEdit}
                salesmen={salesmen}
                setReloadPayments={setReloadPayments}
                conversionRate={conversionRate}
                setAlertText={setAlertText}
            />

            <DeletePopup />

            <CustomSnackbar />
        </>
    );

    //Event handlers

    function deletePayment(event: React.MouseEvent) {
        if (paymentToDelete) {
            ApiLink.deletePayment(paymentToDelete)
            .then(() => {
                enqueueSnacks("Suppression réussie");
                setReloadPayments(true);
            })
            .catch((err) => {
                enqueueSnacks(undefined, undefined, "Erreur de suppression du paiement");
                console.error(err);
            });
        }
    }

    //Inner components

    function LocalAlertDialog(props: {}): React.ReactElement | null {
        if (alertText) {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion des paiements"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setAlertText(undefined)} autoFocus variant='contained' color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    function DeletePopup(props: { }) : React.ReactElement | null {
        if (paymentToDelete) {
            return (
                <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Suppression d'échéance"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Voulez-vous supprimer le règlement de {paymentToDelete.amount_ht}€ HT à la date du {dayjs(paymentToDelete.date).format('DD/MM/YYYY')} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => setPaymentToDelete(undefined)} autoFocus>Annuler</Button>
                    <Button onClick={e => {
                        setPaymentToDelete(undefined);
                        deletePayment(e);
                    }}
                    variant='contained' color='secondary'
                    >
                    OK
                    </Button>
                </DialogActions>
                </Dialog>
            );
        }
        return null;
    }
};

export default PaymentListPage;