import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';

type Props = {
    clientfile: ApiLink.Clientfile|undefined,
    client: ApiLink.Client|undefined,
    client_company: string,
    client_contact: string,
    onClientChange: (client: ApiLink.Client | undefined) => void,
    onClientCompanyChange: (value: string) => void,
    onClientContactChange: (value: string) => void
};

const ClientChoice = (props : Props) => {
    //States
    const [ clients, setClients ] = React.useState<ApiLink.Client[]>([]);
    const [ clientOptions, setClientOptions ] = React.useState<string[]>([]);
    const [ inputValue, setInputValue ] = React.useState<string>();

    const clientAutocomplete = React.useCallback(
        (event: React.SyntheticEvent, value: string, reason: string) => {
            setInputValue(value);
            const client = clients?.find((element) => element.company_name === value);
            props.onClientChange(client);
            if (!client) {
                props.onClientCompanyChange(value);
            }
    }, [clients, props]);

    //Load
    React.useEffect(() => {
        //Load clients
        ApiLink.getAllClients()
        .then(clients => {
            if (!clients) clients = [];
            setClients(clients);

            //Add client option for autocompletion
            let clientOptions: string[] = [];
            clients.forEach(element => {
                clientOptions.push(element.company_name);
            });
            setClientOptions(clientOptions);
        })
        .catch(err => setClients([]));
    }, []);

    //Layout
    return (
        <Box className='client'>
            <Typography variant='h5' component='h2'>Client</Typography>

            <Grid container columnGap={2} rowGap={2} sx={{ mt: 2 }}>
                <Grid xs={11} item>
                    <Autocomplete
                        options={inputValue ? clientOptions : clientOptions.slice(-20)}
                        freeSolo
                        value={props.client ? props.client.company_name : props.client_company}
                        disabled={props.clientfile ? true : false}
                        renderInput={(params) => <TextField {...params} label="Raison sociale" required />}
                        onInputChange={clientAutocomplete}
                    />
                </Grid>
                <Grid xs={11} item>
                    <TextField
                        fullWidth
                        name='contact'
                        label="Contact" 
                        value={props.client ? props.client.contact : props.client_contact} 
                        disabled={props.clientfile ? true : false}
                        onChange={(e) => props.onClientContactChange(e.target.value)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientChoice;