import * as Api from '../util/ApiCall';

export enum AuthGroup {
    SUPERADMIN = "superadmin",
    ADMIN = "admin",
    SALESMAN = "salesman"
}

export type User = {
    id: number,
    username: string,
    name: string,
    salesman: boolean,
    active: boolean,
    last_active: Date,
    compensation_rate: number,
    auth_group: string
}

export type Client = {
    id: number,
    company_name: string,
    contact: string
}

export type Clientfile = {
    id: number,
    invoice_number: string|null,
    invoice_date: string|null,
    label: string|null,
    amount_ht: number|null,
    amount_ttc: number|null,
    production_cost: number|null,
    total_commissions: number|null,
    remaining_commissions: number|null,
    first_duedate: string|null,
    last_duedate: string|null,
    users_id: number,
    client_id: number,
    client: Client,
    user: User
}

export type Duedate = {
    id: number,
    date: string|null,
    amount_ht: number|null,
    amount_ttc: number|null,
    investment: number|null,
    clientfile_id: number,
    toUpdate: boolean,
}

export type Commissionline = {
    id: number,
    production_cost: number|null,
    margin: number|null,
    sudmedia_share: number|null,
    salesman_share_ht: number|null,
    salesman_share_ttc: number|null,
    payed_share_ht: number|null,
    payed_share_ttc: number|null,
    duedate_id: number,
    duedate: Duedate
}

export type Payment = {
    id: number,
    date: string|null,
    amount_ht: number|null,
    amount_ttc: number|null,
    users_id: number,
    user: User
}

export type Setting = {
    id: number,
    class: string,
    key: string,
    value: string,
    type: string,
    context: string
}

export type PaymentRecap = {
    remaining_this_month_ht: number,
    remaining_this_month_ttc: number,
    remaining_total_ht: number,
    remaining_total_ttc: number,
    content: Object[]
}

export async function logout() : Promise<void> {
    await Api.get("/api/logout");
}

export async function login(userName : string, password : string) : Promise<User | null> {
    const resp = await Api.post("/api/login", {
        'username': userName,
        'password': password
    });
    const data = await resp.json();
    if (!data) {
        console.warn('No login data in response');
        return null;
    }
    if (data.result === false) {
        console.warn('Server error', data.error);
        return null;
    }
    const u = {...data};
    return u;
}

export async function getCurrentUser() : Promise<User | undefined> {
    const resp = await Api.get("/api/currentuser");
    const data = await resp.json();
    if (!data) {
        console.warn('No user data in response');
        return undefined;
    }
    const nu: User = {...data};
    return nu;
}

export async function getSalesmen() : Promise<User[]> {
    const resp = await Api.get("/api/salesmen");
    const data = await resp.json();
    if (!data) {
        console.warn('No users data in response');
        return [];
    }
    const ret: User[] = [];
    data.forEach((u: any) => {
        const lastLogin: Date | null = u.lastLogin == null ? null : new Date(u.lastLogin);
        const nu: User = {...u, lastLogin: lastLogin }
        ret.push(nu);
    });
    return ret;
}

export async function getAllClients() : Promise<Client[]> {
    const resp = await Api.get("/clientapi/clients");
    const data = await resp.json();
    if (!data) {
        console.warn('No clients data in response');
        return [];
    }
    const ret: Client[] = [];
    data.forEach((c: any) => {
        const nc: Client = {...c};
        ret.push(nc);
    });
    return ret;
}

export async function getClient(id: string) : Promise<Client | undefined> {
    const url = "/clientapi/client/" + id;
    const resp = await Api.get(url);
    const data = await resp.json();
    if (!data) {
        console.warn('No client data in response');
        return undefined;
    } else if (data['id'] == null) {
        console.error('No client found in database for id :' + id);
        return undefined;
    }
    const nc: Client = {...data};
    return nc;
}

/**
 * 
 * @param ids list of ids
 */
export async function deleteClients(ids: number[]) : Promise<void> {
    const resp = await Api.post("/clientapi/deleteclients", {
        'ids': ids
    });
    if (!resp.ok) {
        console.error("/clientapi/deleteclients", resp.status, resp.statusText, resp.body);
        throw Error(`Erreur de communication avec le serveur: ${resp.status}, ${resp.statusText}`);
    }
}

/**
 * 
 * @param client the updated client
 */
export async function updateClient(client: Client) : Promise<void> {
    await Api.post("/clientapi/updateclient", {
        'id': client.id,
        'contact': client.contact,
        'company_name': client.company_name
    });
}

/**
 * 
 * @param client the client to insert
 */
export async function insertClient(client: Client) : Promise<void> {
    await Api.post("/clientapi/insertclient", {
        'contact': client.contact,
        'company_name': client.company_name
    });
}

export async function getClientfile(id: string) : Promise<Clientfile | undefined> {
    const url = "/clientfileapi/clientfile/" + id;
    const resp = await Api.get(url);
    const data = await resp.json();
    if (!data) {
        console.warn('No clientfile data in response');
        return undefined;
    } else if (data['id'] == null) {
        console.error('No clientfile found in database for id :' + id);
        return undefined;
    }
    const ncf: Clientfile = {...data};
    return ncf;
}

export async function getAllClientfiles() : Promise<Clientfile[]> {
    const resp = await Api.get("/clientfileapi/clientfiles");
    const data = await resp.json();
    if (!data) {
        console.warn('No clientfiles data in response');
        return [];
    }
    const ret: Clientfile[] = [];
    data.forEach((cf: any) => {
        const ncf: Clientfile = {...cf};
        ret.push(ncf);
    });
    return ret;
}

/**
 * 
 * @param client_id string representing a number
 * @param salesman_id string representing a number
 * @param startDate string representing a date with format : YYYY-MM-DD
 * @param endDate string representing a date with format : YYYY-MM-DD
 * @returns 
 */
export async function getFilteredClientfiles(client_id: string|null, salesman_id: string|null, startDate: string|null, endDate: string|null) : Promise<Clientfile[]> {
    let params = new URLSearchParams();
    if (client_id) params.append("client_id", client_id);
    if (salesman_id) params.append("salesman_id", salesman_id);
    if (startDate) params.append("start_date", startDate);
    if (endDate) params.append("end_date", endDate);

    const resp = await Api.get("/clientfileapi/filteredclientfiles", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No clientfiles data in response');
        return [];
    }
    const ret: Clientfile[] = [];
    data.forEach((cf: any) => {
        const ncf: Clientfile = {...cf};
        ret.push(ncf);
    });
    return ret;
}

export async function getOngoingClientfiles(salesman_id: string|null) : Promise<Clientfile[]> {
    let params = new URLSearchParams();
    if (salesman_id) params.append("salesman_id", salesman_id);
    const resp = await Api.get("/clientfileapi/ongoingclientfiles", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No clientfiles data in response');
        return [];
    }
    const ret: Clientfile[] = [];
    data.forEach((cf: any) => {
        const ncf: Clientfile = {...cf};
        ret.push(ncf);
    });
    return ret;
}

export async function insertClientfile(clientfile: Clientfile) : Promise<number> {
    const resp = await Api.post("/clientfileapi/insertclientfile", clientfile);
    const data = await resp.json();
    if (!data) {
        console.warn('No returned id for inserted clientfile');
        return 0;
    }

    return data;
}

export async function updateClientfile(clientfile: Clientfile) : Promise<void> {
    await Api.post("/clientfileapi/updateclientfile", clientfile);
}

export async function deleteClientfile(id: number) : Promise<void> {
    await Api.post("/clientfileapi/deleteclientfile", {
        'id': id
    });
}

export async function getDuedates(clientfile_id: string|null) : Promise<Duedate[]> {
    let params = new URLSearchParams();
    if (clientfile_id) params.append("clientfile_id", clientfile_id);

    const resp = await Api.get("/clientfileapi/duedates", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No duedates data in response');
        return [];
    }
    const ret: Duedate[] = [];
    data.forEach((dd: any) => {
        const ndd: Duedate = {...dd, toUpdate: false};
        ret.push(ndd);
    });
    return ret;
}

export async function updateDuedates(clientfile_id: number, salesman_rate: number, production_cost: number, duedates: Duedate[]) : Promise<void> {
    const resp = await Api.post("/clientfileapi/updateduedates",  {
        clientfile_id: clientfile_id,
        salesman_rate: salesman_rate,
        production_cost: production_cost,
        duedates: duedates
    });
    if (!resp.ok) {
        console.error("/clientfileapi/updateduedates", resp.status, resp.statusText, resp.body);
        throw Error(`${resp.status} ${resp.statusText}`);
    }
}

export async function getCommissionlinesForClientfile(clientfile: Clientfile) : Promise<Commissionline[]> {
    let params = new URLSearchParams();
    params.append("clientfile_id", clientfile.id.toString());

    const resp = await Api.get("/clientfileapi/commissionlines", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No commissionlines data in response');
        return [];
    }
    const ret: Commissionline[] = [];
    data.forEach((cl: any) => {
        const ncl: Commissionline = {...cl};
        ret.push(ncl);
    });
    return ret;
}

export async function getCommissionlinesForIds(duedate_ids: Array<number>) : Promise<Commissionline[]> {
    let params = new URLSearchParams();
    params.append("duedate_ids", duedate_ids.join(','));

    const resp = await Api.get("/clientfileapi/commissionlines", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No commissionlines data in response');
        return [];
    }
    const ret: Commissionline[] = [];
    data.forEach((cl: any) => {
        const ncl: Commissionline = {...cl};
        ret.push(ncl);
    });
    return ret;
}

export async function getCommissionlines(duedates: Duedate[], salesman_rate: number, production_cost: number) : Promise<Commissionline[]> {
    const resp = await Api.post("/clientfileapi/commissionlines",  {
        salesman_rate: salesman_rate,
        production_cost: production_cost,
        duedates: duedates,
    });
    const data = await resp.json();
    if (!data) {
        console.warn('No commissionlines data in response');
        return [];
    }
    const ret: Commissionline[] = [];
    data.forEach((cl: any) => {
        const ncl: Commissionline = {...cl};
        ret.push(ncl);
    });
    return ret;
}

export async function getAllPayments() : Promise<Payment[]> {
    const resp = await Api.get("/paymentapi/payments");
    const data = await resp.json();
    if (!data) {
        console.warn('No payments data in response');
        return [];
    }
    const ret: Payment[] = [];
    data.forEach((pm: any) => {
        const npm: Payment = {...pm};
        ret.push(npm);
    });
    return ret;
}

/**
 * @param salesman_id string representing a number
 * @param startDate string representing a date with format : YYYY-MM-DD
 * @param endDate string representing a date with format : YYYY-MM-DD
 * @returns 
 */
export async function getFilteredPayments(salesman_id: string|null, startDate: string|null, endDate: string|null) : Promise<Payment[]> {
    let params = new URLSearchParams();
    if (salesman_id) params.append("salesman_id", salesman_id);
    if (startDate) params.append("start_date", startDate);
    if (endDate) params.append("end_date", endDate);

    const resp = await Api.get("/paymentapi/filteredpayments", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No payments data in response');
        return [];
    }
    const ret: Payment[] = [];
    data.forEach((cf: any) => {
        const np: Payment = {...cf};
        ret.push(np);
    });
    return ret;
}

export async function insertPayment(payment: Payment) : Promise<void> {
    await Api.post("/paymentapi/insertpayment", {
        'date': payment.date,
        'amount_ht': payment.amount_ht,
        'amount_ttc': payment.amount_ttc,
        'users_id': payment.users_id
    });
}

/**
 * 
 * @param payment the updated payment
 */
export async function updatePayment(payment: Payment) : Promise<void> {
    await Api.post("/paymentapi/updatepayment", {
        'id': payment.id,
        'date': payment.date,
        'amount_ht': payment.amount_ht,
        'amount_ttc': payment.amount_ttc,
        'users_id': payment.users_id
    });
}

export async function deletePayment(payment: Payment) : Promise<void> {
    await Api.post("/paymentapi/deletepayment", {
        'id': payment.id,
        'amount_ht': payment.amount_ht,
        'users_id': payment.users_id
    });
}

export async function getPaymentRecapDates(salesman_id: string|null, startDate: string|null, endDate: string|null) : Promise<string[]> {
    let params = new URLSearchParams();
    if (salesman_id) params.append("salesman_id", salesman_id);
    if (startDate) params.append("start_date", startDate);
    if (endDate) params.append("end_date", endDate);

    const resp = await Api.get("/paymentapi/paymentrecapdates", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No paymentrecap data in response');
        return []
    }

    return data;
}

export async function getPaymentRecapContent(salesman_id: string|null, recap_dates: string[]) : Promise<PaymentRecap> {
    const resp = await Api.post("/paymentapi/paymentrecapcontent", {
        'salesman_id': salesman_id,
        'recap_dates': recap_dates
    });
    const data = await resp.json();
    if (!data) {
        console.warn('No paymentrecap data in response');
        return {} as PaymentRecap;
    }
    const ret: PaymentRecap = data as PaymentRecap;
    return ret;
}

export async function getDuedateDetails(salesman_id: string|null, month: string, year: string) : Promise<Object[]> {
    let params = new URLSearchParams();
    if (salesman_id) params.append("salesman_id", salesman_id);
    params.append("month", month);
    params.append("year", year);

    const resp = await Api.get("/paymentapi/duedatedetails", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No duedate details data in response');
        return [];
    }
    return data;
}

export async function getPaymentDetails(salesman_id: string|null, month: string, year: string) : Promise<Object[]> {
    let params = new URLSearchParams();
    if (salesman_id) params.append("salesman_id", salesman_id);
    params.append("month", month);
    params.append("year", year);

    const resp = await Api.get("/paymentapi/paymentdetails", params);
    const data = await resp.json();
    if (!data) {
        console.warn('No payment details data in response');
        return [];
    }
    return data;
}

export async function getSetting(name: string) : Promise<Setting | undefined> {
    const url = "/api/setting/" + name;
    const resp = await Api.get(url);
    const data = await resp.json();
    if (!data) {
        console.warn('No setting data in response');
        return undefined;
    } else if (data['id'] == null) {
        console.error('No Setting found in database for name :' + name);
        return undefined;
    }
    const ns: Setting = {...data};
    return ns;
}
