import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import * as ApiLink from '../../link/ApiLink';
import { MouseEvent } from 'react';

type Props =  {
    onLoggeOut: () => void
}

export default function LogoutItem({ onLoggeOut } : Props) {
    const handleLogout = (e : MouseEvent) => {
        e.preventDefault();

        ApiLink.logout()
            .catch(reason => {
                console.error(reason);
                window.alert(reason);
            })
            .finally(() => {
                if (onLoggeOut) {
                    onLoggeOut();
                }
            });
    };

    return (
        <MenuItem onClick={ handleLogout }><ListItemIcon><LogoutIcon /></ListItemIcon><ListItemText>Déconnexion</ListItemText></MenuItem>
    );
}