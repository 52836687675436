import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Toolbar } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import { Decimal } from 'decimal.js-light';
import ClientChoice from '../../components/clientfiles/ClientChoice';
import SalesmanChoice from '../../components/clientfiles/SalesmanChoice';
import InvoiceFields from '../../components/clientfiles/InvoiceFields';
import DuedatePanel from '../../components/clientfiles/DuedatePanel';
import CommissionPanel from '../../components/clientfiles/CommissionPanel';
import CustomSnackbar, { enqueueSnacks } from '../../components/common/CustomSnackbar';
import { useSelector } from 'react-redux';
import { LoginState } from '../../redux/features/loginSlice';

type Props = { };

const ClientfilePage = (props : Props) => {
    const navigate = useNavigate();

    //Main states
    const { clientfileId } = useParams();
    const { user } = useSelector((state: { login: LoginState }) => state.login);
    const [ clientfile, setClientfile ] = React.useState<ApiLink.Clientfile>();
    const [ alertText, setAlertText ] = React.useState<string>();
    const [ toDelete, setToDelete ] = React.useState<boolean>(false);
    const [ validateText, setValidateText ] = React.useState<string>();
    const [ conversionRate, setConversionRate ] = React.useState<number>(1);
    const [ loading, setLoading ] = React.useState<boolean>(false);

    //Client search and autocompletion
    const [ selectedClient, setSelectedClient ] = React.useState<ApiLink.Client>();
    const [ companyClientInput, setCompanyClientInput ] = React.useState<string>('');
    const [ contactClientInput, setContactClientInput ] = React.useState<string>('');

    const onClientChange = (client: ApiLink.Client|undefined) => {
        setSelectedClient(client);
    };

    const onClientCompanyChange = (value: string) => {
        setCompanyClientInput(value);
    };

    const onClientContactChange = (value: string) => {
        setContactClientInput(value);
    };

    //Invoice states
    const invoiceInitState: {number: string, date: string|null, label: string, productionCost: string} = 
    { number: '', date: null, label: '', productionCost: '' };
    const [ invoiceState, setInvoiceState ] = React.useState(invoiceInitState);
    const [ amountHT, setAmountHT ] = React.useState<string>('');
    const [ amountTTC, setAmountTTC ] = React.useState<string>('');
    //Used to block amountHT and amountTTC when there are duedates before today
    const [ blockAmount, setBlockAmount ] = React.useState<boolean>(false);
    //Is used to block any changes on duedates and commissions if this is set to true
    const [ blockDuedates, setBlockDuedates ] = React.useState<boolean>(false);

    //Salesman states
    const [ selectedSalesman, setSelectedSalesman ] = React.useState<ApiLink.User>();
    const [ disableSalesmanSelection, setDisableSalesmanSelection ] = React.useState<boolean>(false);

    const onSalesmanChange = (salesman: ApiLink.User|undefined) => {
        setSelectedSalesman(salesman);
        setReloadCommissionlines(true);
    };

    //Duedates states
    const [ duedates, setDuedates ] = React.useState<ApiLink.Duedate[]>([]);
    const [ remainingAmount, setRemainingAmount ] = React.useState<Decimal>(new Decimal(0));

    const onDuedatesAddition = (newDuedates: ApiLink.Duedate[]) => {
        setBlockDuedates(true);
        setDuedates(previous => [...previous, ...newDuedates]);
        setReloadCommissionlines(true);
    }

    const onDuedateUpdate = (duedate: ApiLink.Duedate) => {
        setBlockDuedates(true);
        //Update duedate list
        const updated = duedates.map((element) => {
            if (element.id === duedate.id) {
                return duedate;
            } else {
                return element;
            }
        });

        setDuedates(updated);
        setReloadCommissionlines(true);
    };

    const onDuedateDelete = (duedate: ApiLink.Duedate) => {
        setBlockDuedates(true);
        setDuedates(duedates.filter(element => element.id !== duedate.id));
        setReloadCommissionlines(true);
    };

    //Commissionlines states
    const [ commissionlines, setCommissionlines ] = React.useState<ApiLink.Commissionline[]>([]);
    const [ reloadCommissionlines, setReloadCommissionlines ] = React.useState<boolean>(false);

    const recalculateCommissionlines = React.useCallback(() => {
        setReloadCommissionlines(false);

        console.log('recalculating commissionlines');
        let sr = selectedSalesman ? selectedSalesman.compensation_rate : 0;
        let pc = invoiceState.productionCost === '' ? 0 : Number(invoiceState.productionCost);

        ApiLink.getCommissionlines(duedates, sr, pc)
        .then((commissionlines) => {
            setCommissionlines(commissionlines);
            enqueueSnacks("Commissions mises à jour")
        })
        .catch(err => console.log(err));

        if (clientfile && (clientfile.remaining_commissions ?? 0) < (clientfile.total_commissions ?? 0)) {
            setBlockDuedates(true);
        } else {
            setBlockDuedates(false);
        }
    }, [invoiceState.productionCost, selectedSalesman, duedates, clientfile]);

    //Load
    const loadClientFile = React.useCallback((clientfileId: string) => {
        console.log("Loading clientfile with id : ", clientfileId);
        ApiLink.getClientfile(clientfileId)
        .then(clientfile => {
            if (clientfile) {
                setClientfile(clientfile);
                //Block all actions related to duedates if a payment is affecting this clientfile
                if ((clientfile.remaining_commissions ?? 0) < (clientfile.total_commissions ?? 0)) {
                    setBlockDuedates(true);
                }
                //Block the modification of the total amount if the first duedate has passed
                if (clientfile.first_duedate && (clientfile.first_duedate < new Date().toISOString().substring(0,10))) {
                    setBlockAmount(true);
                }
                setDisableSalesmanSelection(true);
                setSelectedClient(clientfile.client);
                setSelectedSalesman(clientfile.user);
                setInvoiceState({
                    number: clientfile.invoice_number ? clientfile.invoice_number : '', 
                    date: clientfile.invoice_date, 
                    label: clientfile.label ? clientfile.label : '',
                    productionCost: clientfile.production_cost ? clientfile.production_cost.toString() : ''
                });
                setAmountHT(clientfile.amount_ht ? clientfile.amount_ht.toString() : '');
                setAmountTTC(clientfile.amount_ttc ? clientfile.amount_ttc.toString() : '');
                setRemainingAmount(new Decimal(clientfile.amount_ttc ? clientfile.amount_ttc : 0));

                //Load duedates for the retrieved clientfile
                ApiLink.getDuedates(clientfile.id.toString())
                .then(duedates => {
                    setDuedates(duedates);
                })
                .catch(err => setAlertText("Une erreur est survenue pendant le chargement des échéances : " + err.message));

                //Load commissionlines for the retrieved clientfile
                ApiLink.getCommissionlinesForClientfile(clientfile)
                .then(commissionlines => {
                    setCommissionlines(commissionlines);
                })
                .catch(err => setAlertText("Une erreur est survenue pendant le chargement des commissions : " + err.message));
            }
        })
        .catch(err => {
            setClientfile(undefined);
            setAlertText("Une erreur est survenue pendant le chargement du dossier : " + err.message);
        });
    }, []);

    React.useEffect(() => {
        //Load clientfile
        if (clientfileId) {
            loadClientFile(clientfileId);
        } else if (user && user.auth_group === ApiLink.AuthGroup.SALESMAN) {
            setSelectedSalesman(user);
            setDisableSalesmanSelection(true);
        }

        //Load settings
        ApiLink.getSetting('clientfile.amount.conversion.rate')
        .then(setting => {
            if (setting) {
                setConversionRate(Number(setting.value))
            }
        })
        .catch(err => setAlertText("Impossible de récupérer le taux de conversion : " + err.message))
    }, [clientfileId, loadClientFile, user]);

    //Update remaining amount when states amountHT or duedates change
    React.useEffect(() => {
        let value: Decimal = new Decimal(amountHT === '' ? 0 : Number(amountHT));
        duedates.forEach(element => {
            value = value.minus(element.amount_ht ? element.amount_ht : 0);
        });
        setRemainingAmount(value);
    }, [amountHT, duedates]);

    /**
     * Update commissionlines values
     * There's a timeout to avoid updating commissionlines many times for no reason.
     * This effect will wait 1 second before executing the update. If it is called before that time, the delay will reset
     */
    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (reloadCommissionlines) {
                recalculateCommissionlines();
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [reloadCommissionlines, recalculateCommissionlines]);


    //Layout
    return (
        <>
        <Box component='form' className='clientfile-form' onSubmit={submitForm}>
            <Grid container>
                <Grid item xs={6}>
                    <ClientChoice
                        clientfile={clientfile}
                        client={selectedClient}
                        client_company={companyClientInput}
                        client_contact={contactClientInput}
                        onClientChange={onClientChange}
                        onClientCompanyChange={onClientCompanyChange}
                        onClientContactChange={onClientContactChange}
                    />

                    <SalesmanChoice
                        salesman={selectedSalesman}
                        onSalesmanChange={onSalesmanChange}
                        disableSalesmanSelection={disableSalesmanSelection}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InvoiceFields
                        setAlertText={setAlertText}
                        invoiceState={invoiceState}
                        setInvoiceState={setInvoiceState}
                        onProductionCostChange={setReloadCommissionlines}
                        amountHT={amountHT}
                        amountTTC={amountTTC}
                        setAmountHT={setAmountHT}
                        setAmountTTC={setAmountTTC}
                        blockAmount={blockAmount}
                        blockDuedates={blockDuedates}
                        duedateRequired={duedates.length > 0}
                        conversionRate={conversionRate}
                    />
                </Grid>
            </Grid>

            <DuedatePanel
                clientfile={clientfile}
                duedates={duedates}
                setAlertText={setAlertText}
                onDuedatesAddition={onDuedatesAddition}
                onDuedateUpdate={onDuedateUpdate}
                onDuedateDelete={onDuedateDelete}
                conversionRate={conversionRate}
                remainingAmount={remainingAmount}
                blockDuedates={blockDuedates}
            />

            <CommissionPanel
                commissionlines={commissionlines}
            />

            <Toolbar sx={{ justifyContent: 'space-between', mt:2, gap: '10px', px: 0, '@media (min-width: 600px)': {padding: "0"}}}>
                {clientfile ?
                    <Button type='submit' variant='contained' color='primary'>
                        <SaveIcon sx={{ fontSize: '1.5em', marginRight: '.25em' }}/>
                        Appliquer les changements
                    </Button>
                    : 
                    <Button type='submit' variant='contained' color='secondary'>
                        <AddCircleIcon sx={{ fontSize: '1.5em', marginRight: '.25em' }}/>
                        Créer le dossier
                    </Button>
                }
                {clientfile && !blockAmount && !blockDuedates ?
                    <Button variant='contained' color='error' 
                    onClick={e => {
                        e.preventDefault();
                        setToDelete(true);
                    }}
                    >
                        <DeleteIcon sx={{ fontSize: '1.5em', marginRight: '.25em' }}/>
                        Supprimer
                    </Button>
                    : 
                    ''
                }
            </Toolbar>
            
        </Box>

        <LocalAlertDialog />
        <ValidateDialog />
        <DeleteDialog />
        <CustomSnackbar />

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        </>
    );

    //Event handlers

    function submitForm(event: React.FormEvent) {
        event.preventDefault();
        
        if (remainingAmount.greaterThan(0)) {
            setValidateText('Les échéances client du dossier ne comblent pas son montant total, continuer quand même ?');
        } else if (remainingAmount.lessThan(0)) {
            setAlertText('Impossible d\'enregistrer le dossier car les échéances client dépassent le montant total.');
        } else {
            handleClientfileAction();
        }
    }

    function handleClientfileAction() {
        if (clientfile) {
            updateClientfile(clientfile);
        } else {
            createClientfile();
        }
    }

    function updateClientfile(clientfile: ApiLink.Clientfile) {
        console.log("updating client file with id :" + clientfile.id);
        setLoading(true);
        //Placeholder client to create the clientfile
        const client: ApiLink.Client = {} as ApiLink.Client;
        //Placeholder user to create the clientfile
        const user: ApiLink.User = {} as ApiLink.User;
        //Create a clientfile with updated values
        const ncf: ApiLink.Clientfile = {
            id: clientfile.id,
            invoice_number: invoiceState.number === '' ? null : invoiceState.number,
            invoice_date: invoiceState.date === '' ? null : invoiceState.date,
            label: invoiceState.label === '' ? null : invoiceState.label,
            amount_ht: amountHT === '' ? null : Number(amountHT),
            amount_ttc: amountTTC === '' ? null : Number(amountTTC),
            production_cost: invoiceState.productionCost === '' ? null : Number(invoiceState.productionCost),
            total_commissions: null, //Field updated with duedates update
            remaining_commissions: null, //Field updated with duedates update
            first_duedate: null, //Field updated with duedates update
            last_duedate: null, //Field updated with duedates update
            users_id: 0,
            client_id: 0,
            client: client,
            user: user
        }

        ApiLink.updateClientfile(ncf)
        .then(() => {
            let sr = selectedSalesman ? selectedSalesman.compensation_rate : 0;
            let pc = invoiceState.productionCost === '' ? 0 : Number(invoiceState.productionCost);
            ApiLink.updateDuedates(clientfile.id, sr, pc, duedates)
            .then(() => {
                enqueueSnacks("Mise à jour du dossier réussie");
                loadClientFile(clientfile.id.toString())
            })
            .catch(err => setAlertText(err.message));
        })
        .catch(err => setAlertText(err.message))
        .finally(() => setLoading(false));
    }

    function createClientfile() {
        setLoading(true);
        console.log("Creating new client file");
        //If no selected client, we create a new client and fill it with values
        const client: ApiLink.Client = selectedClient ? selectedClient : {
            id: 0,
            company_name: companyClientInput,
            contact: contactClientInput
        };
        //User should never be undefined as it is required by the form so second part of ternary operator is irrevelevant
        const user: ApiLink.User = selectedSalesman ? selectedSalesman : {} as ApiLink.User;
        //Create a clientfile with all the new values
        const ncf: ApiLink.Clientfile = {
            id: 0,
            invoice_number: invoiceState.number === '' ? null : invoiceState.number,
            invoice_date: invoiceState.date === '' ? null : invoiceState.date,
            label: invoiceState.label === '' ? null : invoiceState.label,
            amount_ht: amountHT === '' ? null : Number(amountHT),
            amount_ttc: amountTTC === '' ? null : Number(amountTTC),
            production_cost: invoiceState.productionCost === '' ? null : Number(invoiceState.productionCost),
            total_commissions: null, //Field updated with duedates update
            remaining_commissions: null, //Field updated with duedates update
            first_duedate: null, //Field updated with duedates update
            last_duedate: null, //Field updated with duedates update
            users_id: selectedSalesman ? selectedSalesman.id : 0,
            client_id: selectedClient ? selectedClient.id : 0,
            client: client,
            user: user
        }

        ApiLink.insertClientfile(ncf)
        .then((id) => {
            //We get the id of the inserted clientfile
            //We add this id to the potential duedates created and update them
            console.log('inserted clientfile id : ', id);
            if (id) {
                const updated = duedates.map((element) => {
                    return {...element, clientfile_id: id};
                });

                let sr = selectedSalesman ? selectedSalesman.compensation_rate : 0;
                let pc = invoiceState.productionCost === '' ? 0 : Number(invoiceState.productionCost);
                ApiLink.updateDuedates(id, sr, pc, updated)
                .then(() => {
                    enqueueSnacks("Création du dossier réussie");
                    navigate('../file/' + id);
                })
                .catch(err => setAlertText(err.message));
            }
        })
        .catch(err => setAlertText(err.message))
        .finally(() => setLoading(false));
    }

    function deleteClientfile() {
        if (clientfile) {
            setLoading(true);
            ApiLink.deleteClientfile(clientfile.id)
            .then(() => {
                navigate("/clientfiles/history");
            })
            .catch((err) => {
                enqueueSnacks(undefined, undefined, "Erreur de suppression du paiement");
                console.error(err);
            })
            .finally(() => setLoading(false));
        }
    }

    //Inner components

    function LocalAlertDialog(props: { }) : React.ReactElement | null {
        if (alertText) {
            return (
                <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion du dossier"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setAlertText(undefined)} autoFocus variant='contained' color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    function ValidateDialog(props: { }) : React.ReactElement | null {
        if (validateText) {
            return (
                <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Validation d'action"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {validateText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => setValidateText(undefined)} autoFocus>Annuler</Button>
                    <Button onClick={e => {
                        setValidateText(undefined);
                        handleClientfileAction();
                    }}
                    variant='contained' color='primary'
                    >
                    Continuer
                    </Button>
                </DialogActions>
                </Dialog>
            );
        }
        return null;
    }

    function DeleteDialog(props: { }) : React.ReactElement | null {
        if (toDelete) {
            return (
                <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Suppression du dossier"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Voulez-vous supprimer ce dossier ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => setToDelete(false)} autoFocus>Annuler</Button>
                    <Button onClick={e => {
                        setToDelete(false);
                        deleteClientfile();
                    }}
                    variant='contained' color='secondary'
                    >
                    OK
                    </Button>
                </DialogActions>
                </Dialog>
            );
        }
        return null;
    }
}

export default ClientfilePage;