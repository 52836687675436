import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import * as appInfo from '../../configs/appInfo';

const MainLayout = () => {
  const { menuState } = useSelector((state: RootState) => state.menuPage);
  const title = menuState  && menuState.pageProps
                          && menuState.pageProps.title 
                  ? menuState.pageProps.title 
                  : "";

  document.title = `${title} - ${appInfo.shortName}`;

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar title={title} />
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p:0 }}
      >
        <Toolbar />
        <Box sx={{p: 2}}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;