import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { dateValueFormatter, euroValueFormatter } from '../../util/UiUtil';

type Props = {
    date: Date|undefined,
    setDate: any,
    setAlertText: any,
    salesman_id: string|null
};

const PaymentRecapDetails = (props : Props) => {
    //States
    const [ paymentDetails, setPaymentDetails ] = React.useState<Object[]>([]);

    //Load
    React.useEffect(() => {
        if (props.date) {
            let month = (props.date.getMonth() + 1).toString();
            let year = props.date.getFullYear().toString();

            ApiLink.getPaymentDetails(props.salesman_id, month, year)
            .then((data) => {
                setPaymentDetails(data);
            })
            .catch((err) => {
                props.setAlertText(err.message);
                props.setDate(undefined);
            });
        }
    }, [props]);

    //Grid Columns
    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Date', sortable: false, width: 100, align: 'center', headerAlign: 'center', valueFormatter: dateValueFormatter },
        { field: 'salesman', headerName: 'Commercial', sortable: false, width: 150, align: 'center', headerAlign: 'center' },
        { field: 'amount_ht', headerName: 'Réglé HT', sortable: false, width: 150, align: 'center', headerAlign: 'center', valueFormatter: euroValueFormatter },
        { field: 'amount_ttc', headerName: 'Réglé TTC', sortable: false, width: 150, align: 'center', headerAlign: 'center', valueFormatter: euroValueFormatter }
    ]

    //Layout
    if (props.date) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Détails des règlements"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Règlements commerciaux effectués sur :
                        <Typography component="span" fontWeight="bold" color="secondary.main">
                            {" " + props.date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </Typography>
                    </DialogContentText>
                    <Box sx={{ '& .MuiDataGrid-footerContainer': { display: 'none' } }}>
                        <DataGrid
                            sx={{ mt: '20px' }}
                            rows={paymentDetails}
                            columns={columns}
                            autoHeight={true}
                            rowSelection={false}
                            disableRowSelectionOnClick={true}
                            slots={{ toolbar: GridToolbar }}
                            density='compact'
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => props.setDate(undefined)} autoFocus variant='contained'>OK</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return null;

};

export default PaymentRecapDetails;