import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js-light';

type Props = { 
    open: boolean,
    setOpen: any,
    onBulkAdd: any,
    amount: Decimal,
    conversionRate: number,
    idCount: number
};

const DuedateBulkAddDialog = (props : Props) => {
    //States
    const [ frequency, setFrequency ] = React.useState<number|null>(null);
    const [ day, setDay ] = React.useState<number|null>(null);
    const [ date, setDate ] = React.useState<string>('');
    const [ amountHT, setAmountHT ] = React.useState<number|null>(null);
    const [ amountTTC, setAmountTTC ] = React.useState<number|null>(null);
    const [ duedateNumber, setDuedateNumber ] = React.useState<number|null>(null);
    const [ investment, setInvestment ] = React.useState<number|null>(null);

    const setHT = (value: string) => {
        setAmountHT(value === '' ? null : Number(value));

        if (value === '') {
            setAmountTTC(null);
        } else {
            let v = new Decimal(Number(value));
            let nv = v.mul(props.conversionRate);
            setAmountTTC(nv.toDecimalPlaces(2).toNumber());
        }
    };

    const setTTC = (value: string) => {
        setAmountTTC(value === '' ? null : Number(value));

        if (value === '') {
            setAmountHT(null);
        } else {
            let v = new Decimal(Number(value));
            let nv = v.dividedBy(props.conversionRate)
            setAmountHT(nv.toDecimalPlaces(2).toNumber());
        }
    };

    //Layout
    if (props.open) {
        return (
            <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <Box component='form' onSubmit={e => {
                    props.setOpen(false);
                    clearPopup();
                    bulkAdd(e);
                }}>
                <DialogTitle id="alert-dialog-title">
                    {"Ajout d'échéances multiples automatique"}
                </DialogTitle>
                <DialogContent>
                    <TextField 
                        id="frequency" name="frequency" label="Fréquence" fullWidth required type='number'
                        variant="standard" sx={{mt: '8px'}} 
                        value={frequency ? frequency : ''}
                        onChange={e => setFrequency(e.target.value === '' ? null : Number(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mois</InputAdornment>,
                          }}
                    />
                    <TextField 
                        id="day" name="day" label="Jour du mois" fullWidth required type='number'
                        variant="standard" sx={{mt: '8px'}} 
                        value={day ? day : ''}
                        onChange={e => setDay(e.target.value === '' ? null : Number(e.target.value))}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            sx={{mt: '8px'}}
                            format='DD/MM/YYYY'
                            label="Débuter le"
                            slotProps={{
                                field:{clearable: true}
                            }}
                            value={date === '' ? null : dayjs(date)}
                            onChange={(value) => setDate(dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : '')}
                        />
                    </LocalizationProvider>
                    <Grid container justifyContent='space-between'>
                        <Grid item xs={5.5}>
                            <TextField 
                                id="amountHT" name="amountHT" label="Montant HT" fullWidth required={duedateNumber == null} type='number'
                                variant="standard" sx={{mt: '8px'}}
                                value={amountHT ?? ''}
                                onChange={e => setHT(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={5.5}>
                            <TextField 
                                id="amountTTC" name="amountTTC" label="Montant TTC" fullWidth required={duedateNumber == null} type='number'
                                variant="standard" sx={{mt: '8px'}}
                                value={amountTTC ?? ''}
                                onChange={e => setTTC(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <TextField 
                        id="duedateNumber" name="duedateNumber" label="Échéances" fullWidth required={amountHT == null} type='number'
                        variant="standard" sx={{mt: '8px'}} 
                        value={duedateNumber ? duedateNumber : ''}
                        onChange={e => setDuedateNumber(e.target.value === '' ? null : Number(e.target.value))}
                    />
                    <TextField 
                        id="investment" name="investment" label="Investissement HT" fullWidth required type='number'
                        variant="standard" sx={{mt: '8px'}} 
                        value={investment ?? ''}
                        onChange={e => setInvestment(e.target.value === '' ? null : Number(e.target.value))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={_e => {props.setOpen(false); clearPopup()}} autoFocus variant='text' color='primary'>Annuler</Button>
                    <Button type='submit' variant='contained' color='primary'><EditCalendarIcon sx={{fontSize: '1.2em', marginRight: '.25em'}} />Créer les échéances</Button>
                </DialogActions>
                </Box>
            </Dialog>
        );
    }

    return null;

    //Event handlers

    function bulkAdd(event: React.FormEvent) {
        event.preventDefault();
        event.stopPropagation();

        let duedates: ApiLink.Duedate[] = [];
        let duedateFrequency = frequency ? frequency : 1; //frequency should never be null as it is required in form
        let duedateDay = day ? day : 1; //day should never be null as it is required in form
        let id = props.idCount;
        let iterationNumber: Decimal = new Decimal(duedateNumber ?? 0);
        let ht: Decimal = new Decimal(amountHT ?? 0);
        let ttc: Decimal = ht.mul(props.conversionRate).toDecimalPlaces(2);
        let total: Decimal = new Decimal(0);

        //If we only have an amount
        if (duedateNumber == null) {
            if (amountHT) { //Should always happen since field is required in form when no duedateNumber is entered
                iterationNumber = new Decimal(props.amount.dividedBy(amountHT)).toDecimalPlaces(0, 3);
            }
        } 
        //If we only have a defined number of duedate
        else if (amountHT == null) {
            ht = new Decimal(props.amount.dividedBy(duedateNumber)).toDecimalPlaces(2, 3);
            ttc = ht.mul(props.conversionRate).toDecimalPlaces(2);

            if (ht.isZero()) {
                iterationNumber = new Decimal(0);
            }
        }

        for (let i = 0; iterationNumber.greaterThan(i); i++) {
            let startDate: Date = date === '' ? new Date() : new Date(date);
            let foundDate = findNextdate(startDate, duedateDay, i*duedateFrequency); 
            total = total.add(ht);
            duedates.push({
                id: id,
                date: foundDate,
                amount_ht: ht.toNumber(),
                amount_ttc: ttc.toNumber(),
                investment: investment,
                clientfile_id: 0,
                toUpdate: true
            });

            //Update id for next add
            id -= 1;
        }

        let diff: Decimal = props.amount.minus(total);
        if (diff.greaterThan(0) && duedates.length > 0) {
            let lastDuedate = duedates[duedates.length - 1];
            if (lastDuedate) {
                ht = ht.add(diff).toDecimalPlaces(2);
                ttc = ht.mul(props.conversionRate).toDecimalPlaces(2);
                lastDuedate.amount_ht = ht.toNumber();
                lastDuedate.amount_ttc = ttc.toNumber();
            }
        }

        props.onBulkAdd(duedates);
    }

    function findNextdate(date: Date, day: number, shift: number): string {
        let monthShift = shift;
        if (date.getDate() > day) {
            monthShift += 1;
        }
        date.setMonth(date.getMonth() + monthShift);
        let dayCount = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        if (dayCount < day) {
            date.setDate(dayCount);
        } else {
            date.setDate(day);
        }
        
        let month = date.getMonth() + 1;
        let dateString = [
            date.getFullYear(),
            month.toString().padStart(2, '0'),
            date.getDate().toString().padStart(2, '0')
        ].join('-');

        return dateString;
    }

    function clearPopup() {
        setFrequency(null);
        setDay(null);
        setDay(null);
        setAmountHT(null);
        setAmountTTC(null);
        setDuedateNumber(null);
        setInvestment(null);
    }
};

export default DuedateBulkAddDialog;