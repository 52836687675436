import { SnackbarProvider, SnackbarProviderProps, enqueueSnackbar } from "notistack";

/**
 * Custom snackbar from Notistack.
 * Add this snackbar in the components you want to have notifications
 * @param props 
 * @returns 
 */
const CustomSnackbar = (props: SnackbarProviderProps) => {
    props = Object.assign({ anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: 3000 }, props);
    return (
        <SnackbarProvider {...props} />
    );
}

export default CustomSnackbar;

/**
 * function used to display notifications using Notistack.
 * You need to have a snackbar in order to see those notifications
 * @param success 
 * @param warnings 
 * @param error 
 */
export function enqueueSnacks(success?: string, warnings?: string[], error?: string) {
    if (success) {
        enqueueSnackbar(success, { variant: 'success' });
    }
    for (const warning of (warnings || [])) {
        enqueueSnackbar(warning, { variant: 'warning' });
    }
    if (error) {
        enqueueSnackbar(error, { variant: 'error' });
    }
}