import React from "react";
import * as ApiLink from '../../link/ApiLink';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Toolbar, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PaymentAddDialog from "../../components/payments/PaymentAddDialog";
import PaymentRecapGrid from "../../components/payments/PaymentRecapGrid";
import CustomSnackbar from "../../components/common/CustomSnackbar";
import { priceFormatter } from "../../util/UiUtil";
import PaymentRecapFilter from "../../components/payments/PaymentRecapFilter";
import { useSelector } from "react-redux";
import { LoginState } from "../../redux/features/loginSlice";
import sizeConfigs from "../../configs/sizeConfigs";

type Props = {};

const PaymentRecapPage = (props: Props) => {
    const defaultRows = [{'id': 1}, {'id': 2}, {'id': 3}, {'id': 4}, {'id': 5}, {'id': 6}, {'id': 7}];

    //States
    const { user } = useSelector((state: { login: LoginState }) => state.login);
    const [ salesmen, setSalesmen ] = React.useState<ApiLink.User[]>([]);
    const [ conversionRate, setConversionRate ] = React.useState<number>(1);
    const [ alertText, setAlertText ] = React.useState<string>();
    const [ openAddDialog, setOpenAddDialog ] = React.useState<boolean>(false);
    const [ recapRows, setRecapRows ] = React.useState<Object[]>(defaultRows);
    const [ recapDates, setRecapDates ] = React.useState<string[]>([]);
    const [ recapStartDate, setRecapStartDate ] = React.useState<string>();
    const [ recapEndDate, setRecapEndDate ] = React.useState<string>();
    const [ remainingMonthHT, setRemainingMonthHT ] = React.useState<number>(0);
    const [ remainingMonthTTC, setRemainingMonthTTC ] = React.useState<number>(0);
    const [ remainingTotalHT, setRemainingTotalHT ] = React.useState<number>(0);
    const [ remainingTotalTTC, setRemainingTotalTTC ] = React.useState<number>(0);
    

    //Filter
    const initFilterState: {salesman: string|null, startDate: string|null, endDate: string|null} = { salesman: null, startDate: null, endDate: null };
    const [ filterState, setFilterState ] = React.useState(initFilterState);
    const [ reloadDates, setReloadDates ] = React.useState<boolean>(false);
    const [ reloadRecap, setReloadRecap ] = React.useState<boolean>(false);
    const [ salesmanLoggedIn, setSalesmanLoggedIn ] = React.useState<string>();
    const [ showFilterInfos, setShowFilterInfos ] = React.useState<boolean>(false);

    const applyFilter = (s: string|null, sd: string|null, ed: string|null) => {
        setFilterState({...filterState, salesman: s, startDate: sd, endDate: ed});
        setReloadDates(true);
    }

    //Load
    React.useEffect(() => {
        if (user && user.auth_group === ApiLink.AuthGroup.SALESMAN) {
            setFilterState((previous) => ({...previous, salesman: user.id.toString()}));
            setSalesmanLoggedIn(user.id.toString());
        }
        //Load Recap
        setReloadDates(true);

        //Load settings
        ApiLink.getSetting('clientfile.amount.conversion.rate')
        .then(setting => {
            if (setting) {
                setConversionRate(Number(setting.value))
            }
        })
        .catch(err => setAlertText("Une erreur est survenue : " + err.message))

        //Load salesmen
        ApiLink.getSalesmen()
        .then(salesmen => {
            if (!salesmen) salesmen = [];
            setSalesmen(salesmen);
        })
        .catch(err => setSalesmen([]));
    }, [user]);

    React.useEffect(() => {
        if (reloadDates) {
            console.log('Getting recap dates for salesman :' + filterState.salesman + ' | start_date:' + filterState.startDate + ' | end_date:' + filterState.endDate);

            ApiLink.getPaymentRecapDates(filterState.salesman, filterState.startDate, filterState.endDate)
            .then((dates) => {
                setRecapDates(dates);
                if (dates.length > 0) {
                    setRecapStartDate(dates[0]);
                    setRecapEndDate(dates[dates.length - 1]);
                }

                //enqueueSnacks("Dates récupérées");

                //Reload recap for the new dates
                setReloadRecap(true);
            })
            .catch(err => setAlertText(err.message))

            setReloadDates(false);
        }
        if (reloadRecap) {
            console.log('filtering recap for salesman_id:' + filterState.salesman + ' and dates:' + recapDates);

            ApiLink.getPaymentRecapContent(filterState.salesman, recapDates)
            .then((paymentRecap) => {
                setRemainingMonthHT(paymentRecap.remaining_this_month_ht);
                setRemainingMonthTTC(paymentRecap.remaining_this_month_ttc);
                setRemainingTotalHT(paymentRecap.remaining_total_ht);
                setRemainingTotalTTC(paymentRecap.remaining_total_ttc);

                if (paymentRecap.content) {
                    setRecapRows(paymentRecap.content);
                }
                //enqueueSnacks("Chargement terminé");
            })
            .catch(err => setAlertText(err.message))

            setReloadRecap(false);
        }
    }, [reloadDates, reloadRecap, filterState, recapDates]);

    //Layout
    return (
        <Container sx={{width:'fit-content', maxWidth: `calc(100vw - 32px - ${sizeConfigs.sidebar.width})`, px :0, '@media (min-width: 600px)': { padding: "0"},
            '@media (min-width: 1200px)':{maxWidth: `calc(100vw - 32px - ${sizeConfigs.sidebar.width})`}}}
        >
            <PaymentRecapFilter
                salesmen={salesmen}
                applyFilter={applyFilter}
                startDate={recapStartDate}
                endDate={recapEndDate}
                salesmanLoggedIn={salesmanLoggedIn}
                setShowFilterInfos={setShowFilterInfos}
            />

            <PaymentRecapGrid rows={recapRows} dates={recapDates} setAlertText={setAlertText} salesman_id={filterState.salesman} />
            
            <Toolbar sx={{ justifyContent: 'space-between', px: 0, '@media (min-width: 600px)': {padding: "0"}}}>
                <Stack direction="row" gap={2}>
                    <Stack alignItems="flex-end">
                        <Typography>Restant dû à {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })} (inclus) :</Typography>
                        <Typography>Restant dû sur la période affichée :</Typography>
                    </Stack>
                    <Stack alignItems="flex-end">
                        <Typography component="span" fontWeight="bold" color="secondary">{priceFormatter.format(remainingMonthHT) + " HT"}</Typography>
                        <Typography component="span" fontWeight="bold" color="secondary">{priceFormatter.format(remainingTotalHT) + " HT"}</Typography>
                    </Stack>
                    <Stack alignItems="center">
                        <Typography component="span" fontWeight="bold" color="secondary">{"|"}</Typography>
                        <Typography component="span" fontWeight="bold" color="secondary">{"|"}</Typography>
                    </Stack>
                    <Stack alignItems="flex-end">
                        <Typography component="span" fontWeight="bold" color="secondary">{priceFormatter.format(remainingMonthTTC) + " TTC"}</Typography>
                        <Typography component="span" fontWeight="bold" color="secondary">{priceFormatter.format(remainingTotalTTC) + " TTC"}</Typography>
                    </Stack>
                </Stack>
                
                <Button
                    variant='contained' 
                    color='secondary' 
                    onClick={e => setOpenAddDialog(true)} 
                    disabled={salesmanLoggedIn ? true : false}
                    startIcon={<AddCircleOutlineIcon />}>
                        Ajouter un règlement
                </Button>
            </Toolbar>

            <LocalAlertDialog />
            
            <FilterDialog />

            <PaymentAddDialog 
                open={openAddDialog} 
                setOpen={setOpenAddDialog}
                salesmen={salesmen}
                setReloadPayments={setReloadRecap} 
                conversionRate={conversionRate}
                setAlertText={setAlertText}
            />

            <CustomSnackbar />
        </Container>
    );

    //Inner components

    function LocalAlertDialog(props: {}): React.ReactElement | null {
        if (alertText) {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Gestion des paiements"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setAlertText(undefined)} autoFocus variant='contained' color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    };

    function FilterDialog(props: {}): React.ReactElement | null {
        if (showFilterInfos) {
            return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Informations sur le filtre"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Si aucune date n'est renseignée, le recapitulatif sera généré en prenant la première commission non payée trouvée et les 12 mois suivants cette dernière.
                            <br />
                            Si toutes les commissions ont été réglées, le récapitulatif commencera 6 mois avant la date du jour sur 1 année.
                            <br /><br />
                            Si seule la date de début est entrée alors la date de fin sera la date de début +&nbsp;1&nbsp;an.
                            <br /><br />
                            Si seule la date de fin est entrée alors la date de début sera la date de fin -&nbsp;1&nbsp;an.
                            <br /> <br />
                            Si les deux dates sont renseignées, génère le récapitulatif entre ces deux dates.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_e => setShowFilterInfos(false)} autoFocus variant='contained' color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return null;
    }
}

export default PaymentRecapPage;