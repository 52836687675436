import React from 'react';
import * as ApiLink from '../../link/ApiLink';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';

type Props = {
    salesman: ApiLink.User|undefined,
    onSalesmanChange: (client: ApiLink.User | undefined) => void,
    disableSalesmanSelection: boolean
};

const SalesmanChoice = (props : Props) => {
    //States
    const [ salesmen, setSalesmen ] = React.useState<ApiLink.User[]>([]);

    //Load
    React.useEffect(() => {
        //Load salesmen
        ApiLink.getSalesmen()
        .then(salesmen => {
          if (!salesmen) salesmen = [];
          setSalesmen(salesmen);
        })
        .catch(err => setSalesmen([]));
    }, []);

    //Layout
    return (
        <Box className='salesman' sx={{ mt:6 }}>
            <Typography variant='h5' component='h2'>Rémunération</Typography>
            <Grid container sx={{ mt: 2 }} columnGap={2}>
                <Grid item xs={7}>
                    <TextField
                        fullWidth
                        name="salesman" 
                        label="Commercial" 
                        value={props.salesman ? props.salesman.id : ''}
                        onChange={(e) => {
                            const salesman = salesmen.find((element) => element.id === Number(e.target.value));
                            props.onSalesmanChange(salesman);
                        }}
                        select
                        required
                        disabled={props.disableSalesmanSelection ? true : false}
                    >
                        <MenuItem key="all" value="">Aucun</MenuItem>
                        {salesmen.map((salesman) => {
                            return (
                                <MenuItem key={salesman.id} value={salesman.id}>{salesman.name}</MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField label='Taux de rémunération' value={props.salesman ? props.salesman.compensation_rate : ''} disabled />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SalesmanChoice;